import { faAddressCard, faEdit, faTriangleExclamation } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import EmptyDocuments from "../../assets/drawe-documents.png";
import PdfIcon from "../../assets/pdf.png";
import Loader from "../../components/Loader";
import Button from "../../components/form/button";
import Radio from "../../components/form/radio";
import { getMeDocuments, getMeUserInfo } from "../../services/auth.service";

const MyDocuments = (props) => {
    const navigate = useNavigate();
    const [documents, setDocuments] = useState([]);
    const [userInfo, setUserInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [hasPaidInvestigation, setHasPaidInvestigacion] = useState(false);
    const [investigationStatus, setInvestigationStatus] = useState(false);

    const getDocuments = async () => {
        try {
            setLoading(true);
            const resp = await getMeDocuments(props.token);
            if (resp.data) {
                setDocuments(resp.data.documents);
            }
        } catch (error) {
            console.error("Error fetching documents:", error);
        } finally {
            setLoading(false);
        }
    };

    const getMyInfo = async () => {
        try {
            setLoading(true);
            const resp = await getMeUserInfo(props.token);
            if (resp.data) {
                setUserInfo(resp.data.user);
                setHasPaidInvestigacion(resp.data.user.investigation_paid);
                setInvestigationStatus(resp.data.user.investigationStatus);
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getMyInfo();
        getDocuments();
    }, []);

    // Validar si tenemos un usuario valido antes del render
    if (!userInfo) {
        return (
            <Loader />
        );
    }

    //Validar que el usuario tenga roles
    if (!userInfo.roles) {
        return (
            <Loader />
        );
    }

    return (
        <>
            <div className="px-4 lg:px-8 py-6">
                <div className="flex justify-between items-center mb-6">
                    <h1 className="text-3xl font-bold text-gray-800">Documentos añadidos</h1>
                    <div>
                        <div className="relative group">
                            <Button
                                disabled={loading}
                                onClick={() =>
                                    navigate("/admin/personal-panel/documents-upload")}
                                className={`flex items-center px-4 py-2 text-sm rounded-xl shadow-lg transition-all duration-300 ${loading
                                    ? "bg-gray-300 text-gray-700 cursor-not-allowed"
                                    : "bg-purple-800 text-white hover:bg-purple-600"
                                    }`}
                            >
                                <FontAwesomeIcon icon={faEdit} className="mr-2" />
                                Editar documentos
                            </Button>
                        </div>

                    </div>
                </div>
                <div className="bg-gray-50 rounded-lg shadow-md p-6">
                    {loading ? (
                        <div className="flex justify-center items-center h-64">
                            <Loader />
                        </div>
                    ) : documents.length > 0 ? (
                        <>
                            {userInfo.roles.includes('lessor') && (
                                <motion.div
                                    className="flex items-center bg-blue-100 p-4 rounded-lg mb-4"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <FontAwesomeIcon icon={faAddressCard} className="text-2xl text-blue-500 mr-4" />
                                    <p className="text-lg text-gray-800">
                                        Te has identificado como arrendador(a)
                                    </p>
                                </motion.div>
                            )}
                            {userInfo.roles.includes('renter') && (
                                <motion.div
                                    className="flex items-center bg-purple-100 p-4 rounded-lg mb-6"
                                    initial={{ opacity: 0 }}
                                    animate={{ opacity: 1 }}
                                    transition={{ duration: 0.5 }}
                                >
                                    <FontAwesomeIcon icon={faAddressCard} className="text-2xl text-purple-800 mr-4" />
                                    <p className="text-lg text-gray-800">
                                        Te has identificado como inquilino(a)
                                    </p>
                                </motion.div>
                            )}
                            <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
                                {documents.map((item, index) => (
                                    <motion.div
                                        key={index}
                                        className={`bg-white rounded-lg shadow-lg p-4 ${item.children ? "lg:col-span-2" : ""}`}
                                        initial={{ opacity: 0, y: 20 }}
                                        animate={{ opacity: 1, y: 0 }}
                                        transition={{ duration: 0.5, delay: index * 0.1 }}
                                    >
                                        <div className="flex items-center mb-4">
                                            <Radio checked className="mr-2" />
                                            <p className="text-lg font-semibold">{item.name}</p>
                                        </div>
                                        {!item.children && (
                                            <div className="mb-4 h-48 flex justify-center items-center">
                                                {String(item.url).includes("pdf") ? (
                                                    <img
                                                        src={PdfIcon}
                                                        alt="PDF document"
                                                        className="h-48 w-auto mx-auto"
                                                    />
                                                ) : (
                                                    item.url ? (
                                                        <img
                                                            src={item.url}
                                                            alt="Image document"
                                                            className="h-48 w-full object-contain"
                                                        />
                                                    ) : (
                                                        <div className="text-center">
                                                            <p>Aún no tienes documentos añadidos</p>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        )}
                                        {item.children && (
                                            <div className="mt-4 grid grid-cols-1 md:grid-cols-3 gap-4">
                                                {item.children.map((child, childIndex) => (
                                                    <div
                                                        key={childIndex}
                                                        className="bg-gray-100 rounded-lg shadow-md p-4"
                                                    >
                                                        <p className="text-md font-medium">{child.name}</p>
                                                        <div className="h-32 flex justify-center items-center">
                                                            {String(child.url).includes("pdf") ? (
                                                                <img
                                                                    src={PdfIcon}
                                                                    alt="PDF document"
                                                                    className="h-32 w-auto mx-auto"
                                                                />
                                                            ) : (
                                                                child.url ? (
                                                                    <img
                                                                        src={child.url}
                                                                        alt="Image document"
                                                                        className="h-32 w-full object-contain"
                                                                    />
                                                                ) : (
                                                                    <div className="text-center">
                                                                        <p>Aún no tienes documentos añadidos</p>
                                                                    </div>
                                                                )
                                                            )}
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </motion.div>
                                ))}
                            </div>
                        </>
                    ) : (
                        <div className="flex flex-col items-center justify-center py-16">
                            <img
                                src={EmptyDocuments}
                                alt="Empty documents"
                                className="h-40 mb-4"
                            />
                            <h3 className="text-xl font-semibold text-blue-500 text-center">
                                Aún no tienes documentos añadidos
                            </h3>
                        </div>
                    )}
                </div>
                {userInfo.investigation_requested && (<div className="flex items-center bg-amber-200 p-4 rounded-lg mb-4 mt-4">
                    <FontAwesomeIcon icon={faTriangleExclamation} className="text-2xl text-red-500 mr-2 " />
                    <p className="text-base text-gray-800">
                        Tienes una Solicitud de investigacion pendiente.
                    </p>
                </div>)}


            </div >
        </>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    userData: state.auth.userData,
});

export default connect(mapStateToProps)(MyDocuments);
