import { motion } from 'framer-motion';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PlanCard from '../../../components/cards/PlanCard';
import TarjetaDetallePropiedad from '../../../components/cards/TarjetaDetallePropiedad';
import { getPlans } from '../../../services/payment.service';

const SelectPlanWithPreview = ({ step, setStep, selectedPlan, setSelectedPlan, property }) => {
    const { token } = useSelector((state) => state.auth);
    const [plans, setPlans] = useState([]);

    useEffect(() => {
        fetchPlans();
    }, [token]);

    const fetchPlans = async () => {
        const resp = await getPlans(token);
        if (resp.data) {
            setPlans(resp.data.membershipPlans);
        }
    };

    return (
        <div className='d-flex flex-column'>
            <div className="flex flex-col items-center bg-white p-4 md:p-8 rounded-lg w-full">
                <h3 className="text-[#3E11B5] font-semibold text-2xl mb-6">Vista Previa y Confirmación</h3>
                <p className="text-gray-600 mb-6">Revisa el plan seleccionado y la vista previa de tu propiedad antes de continuar.</p>

                {selectedPlan && (
                    <div className="w-full mb-6">
                        <PlanCard plan={selectedPlan} selectPlan={setSelectedPlan} selectedPlan={selectedPlan} />
                    </div>
                )}

                <motion.div
                    className='container mx-auto my-5 p-4 md:p-6 space-y-8 bg-white rounded-lg mt-3'
                    initial={{ opacity: 0, y: 20 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0 }}
                    transition={{ delay: 0.5 }}
                >
                    <div className='flex justify-end'>
                        <motion.button
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className={`w-full md:w-auto text-white transition duration-300 px-6 py-3 rounded-full ${selectedPlan ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
                                }`}
                            onClick={() => setStep(step + 1)}
                            disabled={!selectedPlan}
                        >
                            Publicar ahora
                        </motion.button>
                    </div>
                    <TarjetaDetallePropiedad property={property} />

                </motion.div>

                <div className="w-full flex flex-col-reverse md:flex-row justify-between items-center gap-4 px-4 md:px-16 mt-6">
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="w-full md:w-auto text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                        onClick={() => setStep(step - 1)}
                    >
                        Regresar
                    </motion.button>
                    <motion.button
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className={`w-full md:w-auto text-white transition duration-300 px-6 py-3 rounded-full ${selectedPlan ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
                            }`}
                        onClick={() => setStep(step + 1)}
                        disabled={!selectedPlan}
                    >
                        Publicar ahora
                    </motion.button>
                </div>

            </div>
        </div>
    );
};

export default SelectPlanWithPreview;
