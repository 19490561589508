import React, { useEffect, useState } from "react";
import { Elements, useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Spinner from "../../components/Loader/Spinner";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function CheckoutFormInvestigation({ onPaymentConfirmed }) {
    const navigate = useNavigate();
    const token = localStorage.getItem('token-yo-rento');
    const [paymentIntentSecret, setPaymentIntentSecret] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [price, setPrice] = useState(null);

    const confirmPayment = async () => {

        if (onPaymentConfirmed) {
            onPaymentConfirmed(true);
        }
    };


    useEffect(() => {
        const createPaymentIntent = async () => {
            setIsLoading(true);
            try {
                const response = await axios.post("/app/payments/investigation-sheet", {}, {
                    headers: { Authorization: `Bearer ${token}` }
                });
                setPaymentIntentSecret(response.data.paymentIntent);
                setPrice(response.data.price)
                console.log(response.data)
            } catch (error) {
                console.error("Error creating payment intent:", error);
            }
            finally {
                setIsLoading(false);
            }
        };

        createPaymentIntent();
    }, [token]);

    return (
        <>
            {isLoading ? (
                <div className="text-center flex justify-center">
                    <Spinner />
                </div>
            ) : paymentIntentSecret ? (
                <Elements stripe={stripePromise} options={{ clientSecret: paymentIntentSecret }}>
                    <StripeForm confirmPaymentProps={confirmPayment} price={price} />
                </Elements>
            ) : (
                <p>Failed to load payment form. Please try again.</p>
            )}
        </>
    );
}

const StripeForm = ({ confirmPaymentProps, price }) => {
    const stripe = useStripe();
    const elements = useElements();
    const navigate = useNavigate();
    const [succeeded, setSucceeded] = useState(false);
    const [error, setError] = useState(null);
    const [processing, setProcessing] = useState(false);
    const [isLoading, setIsLoading] = useState(false);


    const handleSubmit = async (ev) => {
        ev.preventDefault();
        setProcessing(true);

        if (!stripe || !elements) {
            return;
        }

        const payload = await stripe.confirmPayment({
            elements,
            confirmParams: {},
            redirect: "if_required",
        });

        if (payload.error) {
            setError(`Payment failed: ${payload.error.message}`);
            setProcessing(false);
        } else {
            setError(null);
            setProcessing(false);
            setSucceeded(true);
            confirmPaymentProps();
        }
    };

    return (
        <form onSubmit={handleSubmit}>
            <div className="grid grid-cols-12">
                <div className="col-span-12 lg:col-start-2 lg:col-span-10">
                    <div className="flex flex-col justify-center items-center">
                        <h3 className="text-[#3E11B5] text-[15px] font-semibold mb-3">Completar pago</h3>
                        {!isLoading && (
                            <div className="w-full">
                                <PaymentElement />
                                <button
                                    disabled={processing || succeeded || !stripe}
                                    style={{ backgroundColor: succeeded ? "#5cb85c" : "#3E11B5" }}
                                    className="px-6 py-3 text-[#FFF] mt-6 w-full rounded-[4px]"
                                    id="submit"
                                >
                                    {processing ? "Cargando..." : succeeded ? "Completado" : `Pagar $${price}Mxn`}
                                </button>
                                {error && (
                                    <div className="card-error" role="alert">
                                        {error}
                                    </div>
                                )}
                            </div>)}
                    </div>
                </div>
            </div>
        </form>
    );
}

export default CheckoutFormInvestigation;
