import axios from "./index";

export const loginUser = (phone, password) => {
  return axios.post(`/app/core/login`, {
    phone: `+52${phone}`,
    password,
  });
};

export const getMeUserInfo = async (token = null) => {
  return axios.get("/app/core/users", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const deleteAccount = (token = null) => {
  return axios.delete("/app/core/users", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const sendCode = (data) => {
  return axios.post("/app/core/users", { ...data });
};

export const sendCodeEmail = (email, phone) => {
  return axios.post("/app/core/users", { email, phone });
};

export const verifiedCodeEmail = (email, code = "1234") => {
  return axios.post("/app/core/users/phone-code", { email, code });
};

export const verifiedCode = (tel, code = "1234") => {
  return axios.post("/app/core/users/phone-code", { phone: tel, code });
};

export const sendReferralCode = (code) => {
  return axios.patch("/app/core/users/referal-code", { code });
};

export const getFavorites = (token = null) => {
  return axios.get("/app/core/users/favorites", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const putProfileUser = async (data, token = null, header) => {
  return axios.put("/app/core/users", data, {
    headers: { Authorization: `Bearer ${token}`, ...header },
  });
};

export const notificationChanges = (data, token = null) => {
  return axios.put(
    "/app/core/users/configuration",
    {
      ...data,
    },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const sendForgotPassword = (data) => {
  return axios.post("/app/core/users/forgot-password", data);
};

export const validateCodeForgotPassword = (data) => {
  return axios.post("/app/core/users/email-code", data);
};

export const updatePasswordForgotPassword = async (data, token = null) => {
  return axios.put("/app/core/users/forgot-password", data, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getMeDocuments = (token = null) => {
  return axios.get("/app/operation/documents", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getNotifications = (token = null) => {
  return axios.get("/app/core/users/notifications", {
    headers: { Authorization: `Bearer ${token}` },
  })
}

export const signinGoogle = (token = null) => {
  return axios.post("/app/core/social-media", {
    id_token: token,
  });
};

export const getCommentsTenant = (token = null) => {
  return axios.get("/app/core/comments/tenant", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getCommentsRenter = (token = null) => {
  return axios.get("/app/core/comments/renter", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getScoreRenter = (token = null) => {
  return axios.get("/app/core/comments/renter", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getScoreLessor = (token = null) => {
  return axios.get(`/app/core/comments/lessor`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getScoreRenterPassport = (userId, token = null) => {
  return axios.get(`/app/core/comments/renter/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getScoreLessorPassport = (userId, token = null) => {
  return axios.get(`/app/core/comments/lessor/${userId}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getReferrals = (token = null) => {
  return axios.get("/app/core/users/referals", {
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const sendContactForm = (data, propertyId) => {
  return axios.post(`/app/chats/properties/${propertyId}/contact-form`, data);
}

export const getTruoraLink = (token = null) => {
  return axios.get('/app/operation/investigation/identity', {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const getTruoraStatus = (token = null) => {
  return axios.get('/app/core/users/identity-verification/result', {
    headers: { Authorization: `Bearer ${token}` },
  });
}

export const getUnfinishedUser = (token = null) => {
  return axios.get(`/app/core/users/profile/${token}`)
}

export const sendOtpUnfinished = (jwt, phone) => {
  return axios.post(`/app/core/users/profile/${jwt}/code`, { phone })
}

export const fillUser = (data, jwt) => {
  return axios.put(`/app/core/users/profile/${jwt}`, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};