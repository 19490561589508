import React, { useState, useRef } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCloudArrowUp, faPencil } from "@fortawesome/pro-light-svg-icons";
import Success from '../components/modals/modal-success';
import Spinner from '../components/Loader/Spinner';

const DocumentSection = ({ selectedInvestigation, isDocumentsCollapsed }) => {
    const token = localStorage.getItem('token-yo-rento');
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [successText, setSuccessText] = useState("Archivo enviado correctamente");
    const fileInputs = useRef({});
    const [previews, setPreviews] = useState({});

    const handleUploadOrEditFile = async (event, document, isEdit = false) => {
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append(isEdit ? 'document' : document.name, file);

                const url = isEdit
                    ? `/app/operation/documents/${document.id}`
                    : `/app/operation/documents`;

                const method = isEdit ? 'put' : 'post';

                const response = await axios[method](url, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === (isEdit ? 200 : 201)) {
                    setSuccessText(isEdit ? "Archivo actualizado correctamente" : "Archivo subido correctamente");
                    setShowSuccessModal(true);
                    setTimeout(() => setShowSuccessModal(false), 2000);

                    const fileUrl = URL.createObjectURL(file);
                    setPreviews((prev) => ({ ...prev, [document.name]: fileUrl }));
                }
            } catch (error) {
                console.error(`Error ${isEdit ? 'actualizando' : 'subiendo'} el archivo:`, error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const renderPreview = (fileUrl, fileType) => {
        if (fileType.startsWith('image/')) {
            return <img src={fileUrl} alt="Preview" className="mt-2 w-full max-w-xs border rounded-md" />;
        }
        if (fileType === 'application/pdf') {
            return (
                <iframe
                    src={fileUrl}
                    className="mt-2 w-full h-64 border rounded-md"
                    title="PDF Preview"
                ></iframe>
            );
        }
        return <p className="mt-2 text-gray-500">Vista previa no disponible para este tipo de archivo.</p>;
    };

    return (
        isDocumentsCollapsed && (
            <div className="space-y-4 mt-4">
                {selectedInvestigation.documents.map((doc, index) => {
                    const previewUrl = previews[doc.name] || doc.file_location;

                    return (
                        <div key={index} className="p-4 border rounded-md">
                            <p className="text-gray-700">Documento: {doc.name}</p>
                            {previewUrl && (
                                <>
                                    {renderPreview(previewUrl, doc.file_type)}
                                    <button
                                        className="text-blue-600 underline mt-2"
                                        onClick={() => fileInputs.current[doc.name]?.click()}
                                    >
                                        Editar documento
                                    </button>
                                    <input
                                        type="file"
                                        ref={(el) => (fileInputs.current[doc.name] = el)}
                                        className="hidden"
                                        onChange={(event) => handleUploadOrEditFile(event, doc, true)}
                                    />
                                </>
                            )}
                            {!previewUrl && (
                                <div className="mt-2">
                                    <button
                                        className="text-blue-600 underline cursor-pointer"
                                        onClick={() => fileInputs.current[doc.name]?.click()}
                                    >
                                        Subir documento
                                    </button>
                                    <input
                                        type="file"
                                        ref={(el) => (fileInputs.current[doc.name] = el)}
                                        className="hidden"
                                        onChange={(event) => handleUploadOrEditFile(event, doc)}
                                    />
                                </div>
                            )}
                        </div>
                    );
                })}
                {isLoading && <Spinner />}
                <Success show={showSuccessModal} text={successText} />
            </div>
        )
    );
};

export default DocumentSection;
