import { Link } from "react-router-dom";
import AppleIcon from "../../assets/app-store.png";
import FooterCenterIcon from "../../assets/footer-center.png";
import GooglePlayIcon from "../../assets/google-play.png";
import Icon from "../../assets/icon.png";
import { menuFooterTitle, redesSociales } from "../../constants";
import { useState, useEffect } from "react";

const Footer = () => {

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      media.addEventListener('change', listener);
      return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  return (
    <>
      <div className={` ${isMobile ? 'mt-20' : ''} w-full flex flex-col items-center justify-center bg-[#FFF] py-[23px]`}>
        <img src={Icon} width={124} height={41} alt="" />

      </div>
      <div className="bg-[#F6F6F6]">
        <div className="w-full justify-between px-4 mx-auto lg:max-w-7xl md:items-center md:flex md:px-8 pt-5  py-[36px]">
          <div className="p-5 sm:w-3/12">
            <div className="text-[1rem] text-[#1e293]">Directorio de ayuda</div>
            <ul>
              {menuFooterTitle.map((item, index) => (
                <li className="my-2 text-[#060097] text-[1rem]" key={index}>
                  <Link to={`${item.url}`} className="text-[1rem]">
                    {item.name}
                  </Link>
                </li>
              ))}
            </ul>
          </div>
          <div className="sm:w-7/12 flex flex-col items-center">
            <img
              src={FooterCenterIcon}
              width={105}
              height={118}
              className="mb-[32px]"
              alt=""
            />
            <p className="text-[#67768e] text-[100%] text-sm text-center">
              Yo rento ©2024. Derechos reservados.
            </p>
          </div>
          <div className="p-5 sm:w-3/12">
            <div className="text-[100%] text-[#67768e]">Descarga nuestra App</div>
            <div className="flex flex-row gap-3 items-center mt-[24px]">
              <Link
                to="https://apps.apple.com/mx/app/yo-rento/id6467521308"
                className="flex flex-row items-center border border-[#D0CFCF] px-[12px] gap-1 py-[6px] rounded-[4px] text-[#777] text-[12px]"
              >
                <img src={AppleIcon} width={12} height={12} alt="" />
                <span className="flex-initial w-24">App Store</span>
              </Link>
              <Link
                to="https://play.google.com/store/apps/details?id=com.yorento"
                className="flex flex-row items-center border border-[#D0CFCF] px-[12px] gap-1 py-[6px] rounded-[4px] text-[#777] text-[12px]"
              >
                <img src={GooglePlayIcon} width={12} height={12} alt="" />
                <span className="flex-initial w-28">Google Play</span>
              </Link>
            </div>
            <div className="text-[100%] text-[#67768e] mt-[24px]">Síguenos en</div>
            <div className="flex flex-row gap-3 items-center mt-[20px]">
              {redesSociales.map((item, index) => (
                <Link to={item.link} key={index}>
                  <img src={item.image} height={28} width={28} alt="" />
                </Link>
              ))}
            </div>
          </div>
        </div>
        <div className="flex m-auto bg-[#B8DCED] text-gray-800 text-sm flex-col items-center min-h-[30px] py-[20px]">
          <div className="flex flex-row items-center justify-center gap-3 py-2 h-[54px]">
            <Link to="/terminos-y-condiciones" className="text-[#0085BC] text-[15px] cursor-pointer">
              Términos y condiciones de uso
            </Link>
            <Link to="/aviso-de-privacidad" className="text-[#0085BC] text-[15px] cursor-pointer">
              Aviso de privacidad
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
