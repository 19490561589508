import axios from "./index";

export const getInvestigations = async (query, token = null) => {
    return axios.get(`/app/operation/investigations?${query}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};

export const getInvestigationDetails = async (scoreId, token = null) => {
    return axios.get(`/app/operation/investigations/${scoreId}`, {
        headers: { Authorization: `Bearer ${token}` },
    });
};