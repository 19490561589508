import { faBriefcase, faCalendarAlt, faDog, faEnvelope, faIdBadge, faMapMarkerAlt, faUser, faUsers, faPlus, faPlane, faSuitcaseRolling } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import moment from "moment";
import { useOutletContext, Link } from "react-router-dom";
import { getMeUserInfo } from "../../services/auth.service";

const PersonalInfo = () => {

    const [userInfo, setUserInfo] = useState({});
    useEffect(() => {
        const getMyInfo = async () => {
            const resp = await getMeUserInfo();
            if (resp.data) {
                setUserInfo(resp.data.user);
            }
        };

        getMyInfo();
    }, [])

    //const userInfo = useOutletContext().user;

    if (!userInfo) {
        return <p>Cargando información...</p>;
    }

    return (
        <div className="bg-white p-6">

            <div className="flex gap-5 mb-4">
                <h3 className="text-2xl font-semibold text-gray-800 mb-6">
                    Mis datos personales
                </h3>
                <Link
                    to="/admin/personal-panel/complete-passport"
                    className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full flex items-center mb-6">
                    <FontAwesomeIcon icon={faPlus} className="mr-1" />&nbsp;
                    Editar PASE-GURO
                </Link>

            </div>



            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faUser} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Nombre</div>
                        <div className="text-gray-600">{userInfo.name}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faEnvelope} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Email</div>
                        <div className="text-gray-600">{userInfo.email}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faIdBadge} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">CURP</div>
                        <div className="text-gray-600">{userInfo.curp || "No disponible"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faBriefcase} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Situación laboral</div>
                        <div className="text-gray-600">{userInfo.job_situation || "No disponible"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faCalendarAlt} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Fecha de nacimiento</div>
                        <div className="text-gray-600">{moment(userInfo.birth_date).format("YYYY-MM-DD") || "No disponible"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faUsers} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Acompañantes</div>
                        <div className="text-gray-600">{userInfo.has_companions ? "Sí" : "No"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faDog} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Mascotas</div>
                        <div className="text-gray-600">{userInfo.has_pets ? "Sí" : "No"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faPlane} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Motivo del viaje</div>
                        <div className="text-gray-600">{userInfo.trip_reason || "No disponible"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Municipio de interés</div>
                        <div className="text-gray-600">{userInfo.municipality_interest || "No disponible"}</div>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faMapMarkerAlt} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Colonia de interés</div>
                        <div className="text-gray-600">{userInfo.suburb_interest || "No disponible"}</div>
                    </div>
                </div>
                {userInfo.has_companions && (
                    <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                        <FontAwesomeIcon icon={faSuitcaseRolling} className="text-indigo-600 text-2xl mr-4" />
                        <div>
                            <div className="text-lg font-semibold text-gray-700">Perfil del viaje</div>
                            <div className="text-gray-600">{userInfo.companions || "No disponible"}</div>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default PersonalInfo;
