import { useState, useEffect, useRef, useContext } from 'react';
import { AuthContext } from '../../provider/auth';
import axios from 'axios';
import { faCloudArrowUp, faPencil, faClipboardQuestion } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Success from '../../components/modals/modal-success';
import Spinner from '../../components/Loader/Spinner';
import Button from "../../components/form/button";
import { Navigate, useNavigate } from 'react-router-dom';

const MyDocumentsEdit = () => {
    const token = localStorage.getItem('token-yo-rento');
    const [isLoading, setIsLoading] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [isImageDialogVisible, setIsImageDialogVisible] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false)
    const fileInputs = useRef({});
    const successText = "Archivo enviado correctamente"
    const navigate = useNavigate();

    const auth = useContext(AuthContext);
    const user = auth.user;

    useEffect(() => {
        if (user) {
            fetchDocuments();
        }
    }, [user, token]);

    const handleUploadFile = async (event, documentLabel) => {
        console.log("Desde up file");
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append(documentLabel, file);

                const response = await axios.post(`/app/operation/documents`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 201) {
                    setShowSuccessModal(true);
                    setTimeout(() => {
                        setShowSuccessModal(false);
                    }, 1900)
                    const updatedDocuments = await fetchDocuments();
                    setDocuments(updatedDocuments.data.documents);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const handleChangeFile = async (event, documentLabel, documentId) => {
        console.log("Desde change file");
        const file = event.target.files[0];
        if (file) {
            setIsLoading(true);
            try {
                const formData = new FormData();
                formData.append('document', file);

                const response = await axios.put(`/app/operation/documents/${documentId}`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        Authorization: `Bearer ${token}`,
                    },
                });

                if (response.status === 200) {
                    setShowSuccessModal(true);
                    setTimeout(() => {
                        setShowSuccessModal(false);
                    }, 1900)
                    const updatedDocuments = await fetchDocuments();
                    setDocuments(updatedDocuments.data.documents);
                }
            } catch (error) {
                console.error('Error uploading file:', error);
            } finally {
                setIsLoading(false);
            }
        }
    };

    const fetchDocuments = async () => {
        setIsLoading(true);
        try {
            const response = await axios.get('/app/operation/documents', {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            if (response.status === 200) {
                setDocuments(response.data.documents);
            }
        } catch (error) {
            console.error("Error al traer los documentos", error);
        } finally {
            setIsLoading(false);
        }
    };

    const openImageDialog = (url) => {
        setSelectedImage(url);
        setIsImageDialogVisible(true);
    };

    const isImage = (fileType) => /(jpg|jpeg|png|gif|bmp|svg)$/i.test(fileType);
    const isPDF = (fileType) => /pdf$/i.test(fileType);

    return (
        <div className="p-6 bg-white">
            <div className="flex justify-between items-center mb-6">
                <h1 className="text-3xl font-bold text-gray-800">Editar documentos</h1>
                <div>

                </div>
            </div>

            <div className="p-4 bg-blue-50 rounded-lg shadow-md mb-5">
                <div className="flex">
                    <h2 className="text-xl text-purple-600 font-semibold mb-2 mr-1">Cuestionario de investigación:</h2>
                    <Button
                        disabled={isLoading}
                        onClick={() =>
                            navigate("/admin/personal-panel/investigation-questions")}
                        className="flex items-center bg-blue-50 text-purple-600 px-4 py-2 text-xl hover:bg-purple-600 hover:text-white transition-all duration-300"
                    >
                        <FontAwesomeIcon icon={faClipboardQuestion} className="mr-2" />
                        Contestar Cuestionario
                    </Button>
                    <h2 className="text-xl text-purple-600 font-semibold mb-2">{document.name}</h2>
                </div>
            </div>

            <div className="grid gap-6">
                {documents.map((document, index) => (
                    <div key={index} className="p-4 bg-blue-50 rounded-lg shadow-md">
                        <div className="flex">
                            <h2 className="text-xl text-purple-600 font-semibold mb-2 mr-1">Tipo de documento:</h2>
                            <h2 className="text-xl text-purple-600 font-semibold mb-2">{document.name}</h2>
                        </div>

                        {document.children ? (
                            <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                                {document.children.map((child, childIndex) => (
                                    <div key={childIndex} className="p-4 bg-gray-50 rounded-lg shadow-md">
                                        <div className="flex justify-center items-center bg-gray-100 border-2 border-blue-300 rounded-lg h-40 w-40 relative">
                                            {isImage(child.url) ? (
                                                <img
                                                    src={child.url}
                                                    alt={child.name}
                                                    className="object-cover h-full w-full cursor-pointer"
                                                    onClick={() => { openImageDialog(child.url) }}
                                                />
                                            ) : isPDF(child.url) ? (
                                                <i
                                                    className="fas fa-file-pdf text-purple-600 text-6xl cursor-pointer"
                                                    onClick={() => window.open(child.url, '_blank')}
                                                ></i>
                                            ) : (
                                                <div>
                                                    <FontAwesomeIcon icon={faCloudArrowUp} onClick={() => fileInputs.current[child.label].click()} className="cursor-pointer" />
                                                    <input
                                                        type="file"
                                                        ref={(el) => (fileInputs.current[child.label] = el)}
                                                        className="hidden"
                                                        accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf"
                                                        onChange={(event) => handleUploadFile(event, child.label)}
                                                    />
                                                </div>
                                            )}
                                            {child.url && child.validated === false && (
                                                <div>
                                                    <FontAwesomeIcon
                                                        icon={faPencil}
                                                        className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md cursor-pointer"
                                                        onClick={() => fileInputs.current[child.label].click()}
                                                    />
                                                    <input
                                                        type="file"
                                                        ref={(el) => (fileInputs.current[child.label] = el)}
                                                        className="hidden"
                                                        accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf"
                                                        onChange={(event) => handleChangeFile(event, child.label, child.id)}
                                                    />
                                                </div>
                                            )}
                                        </div>

                                        <div className="mt-4">
                                            <label className={`mr-2 ${child.validated ? 'text-green-500' : 'text-gray-500'}`}>
                                                {child.validated ? 'Validado' : 'No Validado'}
                                            </label>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <div className="flex justify-center items-center bg-gray-100 border-2 border-blue-300 rounded-lg h-40 w-40 relative">
                                {isImage(document.url) ? (
                                    <img
                                        src={document.url}
                                        alt={document.name}
                                        className="object-cover h-full w-full cursor-pointer"
                                        onClick={() => { openImageDialog(document.url) }}
                                    />
                                ) : isPDF(document.url) ? (
                                    <i
                                        className="fas fa-file-pdf text-purple-600 text-6xl cursor-pointer"
                                        onClick={() => window.open(document.url, '_blank')}
                                    ></i>
                                ) : (
                                    <div>
                                        <FontAwesomeIcon icon={faCloudArrowUp} onClick={() => fileInputs.current[document.label].click()} className="cursor-pointer" />
                                        <input
                                            type="file"
                                            ref={(el) => (fileInputs.current[document.label] = el)}
                                            className="hidden"
                                            accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf"
                                            onChange={(event) => handleUploadFile(event, document.label)}
                                        />
                                    </div>
                                )}
                                {document.url && document.validated === false && (
                                    <div>
                                        <FontAwesomeIcon
                                            icon={faPencil}
                                            className="absolute top-2 right-2 bg-white rounded-full p-2 shadow-md cursor-pointer"
                                            onClick={() => fileInputs.current[document.label].click()}
                                        />
                                        <input
                                            type="file"
                                            ref={(el) => (fileInputs.current[document.label] = el)}
                                            className="hidden"
                                            accept="image/png, image/gif, image/jpeg, image/jpg, application/pdf"
                                            onChange={(event) => handleChangeFile(event, document.label, document.id)}
                                        />
                                    </div>
                                )}
                            </div>
                        )}

                        <div className="mt-4">
                            {!document.children ? (
                                <label className={`mr-2 ${document.validated ? 'text-green-500' : 'text-gray-500'}`}>
                                    {document.validated ? 'Validado' : 'No Validado'}
                                </label>
                            ) : (
                                <div></div>
                            )}
                        </div>

                    </div>
                ))}
            </div>
            <Success show={showSuccessModal} text={successText} />

            {isImageDialogVisible && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
                    <div className="bg-white p-4 rounded-lg shadow-lg max-w-md">
                        <img src={selectedImage} alt="Selected" className="w-full h-auto" />
                        <button className="mt-4 bg-blue-500 text-white px-4 py-2 rounded-lg" onClick={() => setIsImageDialogVisible(false)}>
                            Close
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MyDocumentsEdit;
