import { faBell, faCheckCircle, faClipboard, faExclamationTriangle, faFileAlt, faHeart, faInfoCircle, faStar, faTrashAlt, faUser, faPencil, faUserCrown, faIdBadge, faUserMagnifyingGlass, faCircleExclamation } from "@fortawesome/pro-solid-svg-icons";
import { faUserGroupSimple } from "@fortawesome/pro-regular-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, Outlet, useLocation } from "react-router-dom";
import UserLogo from "../../assets/user-logo.svg";
import RootLayout from "../../components/Layout";
import LayoutHome from "../../components/LayoutHome";
import Button from "../../components/form/button";
import { deleteAccount, getMeUserInfo } from "../../services/auth.service";
import axios from "axios";
import { useDispatch } from 'react-redux';
import { updateUserData } from '../../redux/actions/auth-action';


const PersonalPanel = (props) => {
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [imageFile, setImageFile] = useState(null);
    const dispatch = useDispatch();

    const userInfo = props.user.user;

    /*
    const getMyInfo = async () => {
        setIsLoading(true);
        const resp = await getMeUserInfo(props.token);
        if (resp.data) {
            setUserInfo(resp.data.user);
        }
        setIsLoading(false);
    };
    */

    const handleDeleteAccount = async () => {
        setIsLoading(true);
        try {
            const response = await deleteAccount();

            if (response.status === 200)
                setSuccessMessage(true);
            setTimeout(() => {
                setSuccessMessage(false);
            }, 2000);
        }
        catch (error) {
            console.error("Error al eliminar cuenta", error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const hasRentedForMoreThanThreeMonths = () => {
        if (!userInfo?.rental_start_date) return false;
        const rentalStartDate = new Date(userInfo.rental_start_date);
        const currentDate = new Date();
        const diffInMonths = (currentDate.getFullYear() - rentalStartDate.getFullYear()) * 12 + (currentDate.getMonth() - rentalStartDate.getMonth());
        return diffInMonths >= 3;
    };

    const handleSubmit = async (formData) => {
        setIsLoading(true);
        try {
            const response = await axios.put('/app/core/users', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                    Authorization: `Bearer ${props.token}`,
                },
            });

            if (response.status === 200) {
                const userData = JSON.parse(localStorage.getItem('userData'));
                if (userData && userData.user) {
                    userData.user.profile_picture = response.data.user.profile_picture;
                    localStorage.setItem('userData', JSON.stringify(userData));
                    const updatedUserData = response.data;
                    dispatch(updateUserData(updatedUserData));
                }

                setSuccessMessage(true);
                setTimeout(() => {
                    setSuccessMessage(false);
                }, 2000);
            }
        } catch (error) {
            console.error('Error uploading image:', error);
            setIsLoading(false);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleImageChange = (e) => {
        //setImageFile(e.target.files[0]);
        const formData = new FormData();
        formData.append('profilePicture', e.target.files[0]);
        handleSubmit(formData);
    };

    const handleEditClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        document.getElementById("upload-photo").click();
    };

    return (
        <RootLayout admin>
            <LayoutHome token={props.token}>
                <div className="container mx-auto px-4 py-8 flex flex-col md:flex-row gap-8">
                    <div className="w-full md:w-1/4 bg-white shadow-xl rounded-2xl p-6 flex flex-col items-center">
                        {isLoading ? (
                            <p>Cargando...</p>
                        ) : (
                            <>
                                <div className="relative w-56 h-56 mb-4">

                                    <div className="relative flex justify-center">
                                        <div className="hex text-purple-800 relative">
                                            <div className="absolute w-5/6 top-3 left-1/2 transform -translate-x-1/2 flex flex-col items-center justify-center">
                                                <img
                                                    src={userInfo?.profile_picture || UserLogo}
                                                    alt="user avatar"
                                                    className="rounded-full h-24 w-24 object-cover border-4 border-yellow-300"
                                                />
                                                <h3 className="text-center mt-2 text-lg font-semibold text-white">{userInfo?.name}</h3>
                                            </div>
                                        </div>

                                        <div className="absolute top-0 right-6 bg-blue-500 w-10 h-10 flex justify-center items-center rounded-full shadow-lg text-white hover:text-black hover:bg-blue-600 transition-colors cursor-pointer"
                                            onClick={handleEditClick}>
                                            <FontAwesomeIcon icon={faPencil} />
                                        </div>
                                        <input
                                            id="upload-photo"
                                            type="file"
                                            style={{ display: "none" }}
                                            onChange={handleImageChange}
                                            accept="image/*"
                                        />
                                    </div>

                                    <svg style={{ visibility: "hidden", position: "absolute" }} width="0" height="0" xmlns="http://www.w3.org/2000/svg" version="1.1">
                                        <defs>
                                            <filter id="goo"><feGaussianBlur in="SourceGraphic" stdDeviation="4" result="blur" />
                                                <feColorMatrix in="blur" mode="matrix" values="1 0 0 0 0  0 1 0 0 0  0 0 1 0 0  0 0 0 19 -9" result="goo" />
                                                <feComposite in="SourceGraphic" in2="goo" operator="atop" />
                                            </filter>
                                        </defs>
                                    </svg>

                                </div>
                                {userInfo?.roles && (
                                    <div className="flex gap-2 mb-4">
                                        {userInfo.roles.includes('lessor') && (
                                            <div className="bg-blue-100 text-blue-600 px-3 py-1 rounded-full flex items-center">
                                                <FontAwesomeIcon icon={faUser} className="mr-1" />
                                                Arrendador
                                            </div>
                                        )}
                                        {userInfo.roles.includes('renter') && (
                                            <div className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full flex items-center">
                                                <FontAwesomeIcon icon={faUser} className="mr-1" />
                                                Inquilino
                                            </div>
                                        )}
                                    </div>
                                )}
                                {userInfo?.investigation_validity ? (
                                    <div className="flex items-center text-center justify-center bg-green-100 text-green-600 px-4 py-2 rounded-full mb-4 shadow-md">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                        Investigación válida hasta {new Date(userInfo.investigation_validity).toLocaleDateString()}
                                    </div>
                                ) : userInfo?.investigation_requested ? (
                                    <div className="flex items-center text-center justify-center bg-yellow-100 text-yellow-600 px-4 py-2 rounded-full mb-4 shadow-md">
                                        <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                                        Investigación pendiente
                                    </div>
                                ) : userInfo?.investigation_done ? (
                                    <div className="flex items-center text-center justify-center bg-green-100 text-green-600 px-4 py-2 rounded-full mb-4 shadow-md">
                                        <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                                        Investigación efectuada
                                    </div>
                                ) : (
                                    <div className="flex items-center text-center justify-center bg-blue-100 text-blue-600 px-4 py-2 rounded-full mb-4 shadow-md">
                                        <FontAwesomeIcon icon={faInfoCircle} className="mr-2" />
                                        Sin investigaciones pendientes
                                    </div>
                                )}

                                <div className="w-full mt-4 space-y-2">
                                    <Link
                                        to="/admin/personal-panel/data-personal"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/data-personal" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faUser} className="mr-2" />
                                        Mi PASE-GURO
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-membership"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-membership" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faUserCrown} className="mr-2" />
                                        Mi membresía
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/validate-identity"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/validate-identity" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faIdBadge} className="mr-2" />
                                        Valida tu identidad
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-investigations"
                                        className={`relative w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-investigations" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faUserMagnifyingGlass} className="mr-2" />
                                        Mis investigaciones
                                        {userInfo.investigation_requested && (
                                            <FontAwesomeIcon icon={faCircleExclamation} className="bg-white text-red-500 rounded-full w-5 h-5 items-center justify-center flex absolute top-0 right-0" />
                                        )}
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-eval"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-eval" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faClipboard} className="mr-2" />
                                        Mis evaluaciones
                                    </Link>

                                    <Link
                                        to="/admin/personal-panel/evaluate-landlord"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/evaluate-landlord" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faClipboard} className="mr-2" />
                                        Evalúa a tu arrendador(a)
                                    </Link>

                                    <Link
                                        to="/admin/personal-panel/my-score"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-score" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faStar} className="mr-2" />
                                        Ver mi puntaje
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-documents"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-documents" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faFileAlt} className="mr-2" />
                                        Mis documentos
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-likes"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-likes" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faHeart} className="mr-2" />
                                        Mis likes
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-alerts"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-alerts" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faBell} className="mr-2" />
                                        Mis alertas
                                    </Link>
                                    <Link
                                        to="/admin/personal-panel/my-referrals"
                                        className={`w-full px-4 py-2 flex items-center justify-center ${location.pathname === "/admin/personal-panel/my-referrals" ? "bg-purple-800 text-white" : "bg-gray-100 text-gray-700"} rounded-lg transition-colors shadow-md`}
                                    >
                                        <FontAwesomeIcon icon={faUserGroupSimple} className="mr-2" />
                                        Mis referidos
                                    </Link>
                                </div>
                                <Button
                                    onClick={handleDeleteAccount}
                                    className="w-full h-12 bg-red-600 text-white font-semibold rounded-lg shadow-md hover:bg-red-700 transition flex items-center justify-center mt-4"
                                >
                                    <FontAwesomeIcon icon={faTrashAlt} className="mr-2" />
                                    Eliminar cuenta
                                </Button>
                                {successMessage && (
                                    <div className="mt-4 p-4 bg-green-100 text-green-700 rounded-lg text-center">
                                        Accion realizada con éxito
                                    </div>
                                )}
                            </>
                        )}
                    </div>

                    <div className="flex-1 bg-white shadow-xl rounded-2xl p-6">
                        {userInfo && <Outlet context={{ user: userInfo, token: props.token }} />}
                    </div>
                </div>
            </LayoutHome>
        </RootLayout>
    );
};

const mapStateToProps = (state) => ({
    token: state.auth.token,
    user: state.auth.userData,
});

export default connect(mapStateToProps)(PersonalPanel);
