import React, { useEffect, useRef } from 'react';
import useGoogleMaps from '../../hooks/useGoogleMaps';

const mapContainerStyle = {
    width: '100%',
    height: '400px',
};

const MapsWrapper = ({ zoom, handleLocationChange, location }) => {
    const mapRef = useRef(null);
    const markerRef = useRef(null);
    const loaded = useGoogleMaps();

    useEffect(() => {
        if (loaded && !mapRef.current && location.lat && location.lng) {
            const google = window.google;
            const map = new google.maps.Map(document.getElementById('map'), {
                center: { lat: location.lat, lng: location.lng },
                zoom: zoom,
            });
            mapRef.current = map;

            const marker = new google.maps.Marker({
                position: { lat: location.lat, lng: location.lng },
                map: map,
                draggable: true,
            });
            markerRef.current = marker;

            // Evento al hacer clic en el mapa
            google.maps.event.addListener(map, 'click', (event) => {
                const newLocation = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                    select: true,
                };
                marker.setPosition(newLocation);
                fetchAddressDetails(newLocation);
            });

            // Evento al arrastrar el marcador
            google.maps.event.addListener(marker, 'dragend', (event) => {
                const newLocation = {
                    lat: event.latLng.lat(),
                    lng: event.latLng.lng(),
                    select: true,
                };
                fetchAddressDetails(newLocation);
            });
        }
    }, [loaded, location.lat, location.lng]);

    useEffect(() => {
        if (markerRef.current && location.lat && location.lng) {
            const latLng = new window.google.maps.LatLng(location.lat, location.lng);
            markerRef.current.setPosition(latLng);
            mapRef.current.panTo(latLng);
        }
    }, [location]);

    const fetchAddressDetails = async (newLocation) => {
        try {
            const response = await fetch(
                `https://maps.googleapis.com/maps/api/geocode/json?latlng=${newLocation.lat},${newLocation.lng}&key=${process.env.REACT_APP_GOOGLE_KEY}`
            );
            const data = await response.json();
            if (!data.results || data.results.length === 0) return;

            const addressComponents = data.results[0].address_components;
            const formatted_address = data.results[0].formatted_address || '';

            const getAddressComponent = (type) =>
                addressComponents.find((component) => component.types.includes(type))?.long_name || '';

            const updatedLocation = {
                lat: newLocation.lat,
                lng: newLocation.lng,
                address: getAddressComponent('route'),
                exterior: getAddressComponent('street_number'),
                interior: location.interior,
                suburb: getAddressComponent('sublocality_level_1') || getAddressComponent('neighborhood'),
                city: getAddressComponent('locality') || getAddressComponent('administrative_area_level_2'),
                state: getAddressComponent('administrative_area_level_1'),
                formatted_address: data.results[0].formatted_address || '',
                select: true,
            };


            handleLocationChange(updatedLocation);
            console.log("llega aqui???", updatedLocation);
        } catch (error) {
            console.error('Error fetching address details:', error);
        }
    };

    return <div id="map" style={mapContainerStyle} />;
};

export default MapsWrapper;
