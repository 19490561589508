import { faArrowLeft, faArrowRight, faImage } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { getPropertie, updatePropertie, updatePropertieImages, deletePhotoProperty } from '../../../services/properties.service';

const UploadPhotosPt2 = ({ step, setStep, setPhotosProperties, photosProperties, setMainPhoto, propertyId }) => {
    const [facade, setFacade] = useState(photosProperties.facade || []);
    const [other, setOther] = useState(photosProperties.other || []);
    const [isLoading, setIsLoading] = useState(false);
    const [progress, setProgress] = useState(0);
    const [showError, setShowError] = useState(false);
    const [sizeError, setSizeError] = useState('');

    const portadaInputRef = useRef(null);
    const otherInputRef = useRef(null);

    const handleFrontImage = (e) => {
        const archivo = e.target.files[0];
        if (archivo) {
            if (archivo.size > 10 * 1024 * 1024) {
                setSizeError("El archivo de portada no debe pesar más de 10 MB");
                return;
            }
            setSizeError("");
            setFacade([archivo]);
        }
    };

    const handleDivClick = (ref) => {
        if (ref.current) {
            ref.current.click();
        }
    };

    const manejarArchivo = (e) => {
        const archivos = e.target.files || e.dataTransfer.files;
        const nuevasFotos = [...other];
        let archivosCargados = 0;

        Array.from(archivos).forEach((archivo) => {
            if (archivo.size > 10 * 1024 * 1024) {
                setSizeError(`El archivo ${archivo.name} no debe pesar más de 10 MB`);
                return;
            }
            archivosCargados += 1;
            nuevasFotos.push(archivo);

            if (archivosCargados === archivos.length) {
                setOther(nuevasFotos);
            }
        });
    };

    const borrarFoto = async (index, item) => {

        if (item.id) {
            await deletePhotoProperty(item.id, propertyId);
            const nuevasFotos = other.filter((_, i) => i !== index);
            setOther(nuevasFotos);
            setPhotosProperties({
                facade: facade,
                other: nuevasFotos
            })
        }
        else {
            const nuevasFotos = other.filter((_, i) => i !== index);
            setOther(nuevasFotos);
        }

    };


    const confirmPhotos = async () => {
        if (other.length > 0 || facade.length > 0) {

            const binaryFacade = facade.filter((image) => typeof image !== "string");
            const binaryOthers = other.filter((item) => typeof item.image !== "string");
            if (binaryFacade.length === 0 && binaryOthers.length === 0) {
                setStep(step + 1);
                return;
            }

            const data = new FormData();
            if (facade.length > 0) {
                data.append("facade", facade[0]);
            }
            other.forEach((foto) => {
                data.append("other", foto);
            });

            setIsLoading(true);
            setProgress(0);


            try {
                const response = await updatePropertieImages(propertyId, data, {
                    onUploadProgress: (progressEvent) => {
                        const total = progressEvent.total;
                        const porcentaje = Math.round((progressEvent.loaded * 100) / total);
                        setProgress(porcentaje);
                    },
                });
                if (response.status === 200) {

                    const newOthers = response.data.images.filter((image) => image.image_type === "Otros")
                    const newFacade = response.data.images.filter((image) => image.image_type === "Fachada")

                    const facade = photosProperties.facade || [];
                    const other = photosProperties.other || [];
                    setPhotosProperties({
                        facade: newFacade.length > 0 ? [newFacade[0].image] : [facade[0]],
                        other: other.concat(newOthers)
                    })
                    setStep(step + 1);

                }
                setShowError(true);
            } catch (error) {
                console.error("Error al subir las fotos", error);
            } finally {
                setIsLoading(false);
            }

        } else {
            alert("Debe subir por lo menos una foto");
        }
    };


    return (
        <div className="max-w-4xl mx-auto w-full">
            <h2 className="text-lg font-semibold mb-4">Selecciona la foto de portada para la publicación</h2>

            <div
                className="border-2 border-dashed border-gray-400 p-4 rounded-lg mb-6 text-center cursor-pointer"
                onClick={() => handleDivClick(portadaInputRef)}
            >
                <input
                    type="file"
                    onChange={handleFrontImage}
                    className="hidden"
                    ref={portadaInputRef}
                />
                <div className="text-blue-500 hover:underline">
                    Haz clic aquí para seleccionar la foto de portada
                </div>

                {facade[0] && (
                    <div className="mt-4">
                        {typeof facade[0] === "string" ? (
                            <img
                                src={facade[0]}
                                alt="Portada"
                                className="w-full h-40 object-cover rounded-lg"
                            />
                        ) : (
                            <img
                                src={URL.createObjectURL(facade[0])}
                                alt="Portada"
                                className="w-full h-40 object-cover rounded-lg"
                            />
                        )}
                    </div>
                )}
            </div>

            <div
                className="border-2 border-dashed border-gray-400 p-4 rounded-lg h-40 text-center cursor-pointer content-center"
                onDrop={(e) => {
                    e.preventDefault();
                    manejarArchivo(e);
                }}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => handleDivClick(otherInputRef)}
            >
                <input
                    type="file"
                    multiple
                    onChange={manejarArchivo}
                    className="hidden"
                    ref={otherInputRef}
                />
                <div className="text-blue-500 hover:underline">
                    Arrastra y suelta tus imágenes aquí o haz clic para seleccionar
                </div>
            </div>

            {isLoading && (
                <div className="mt-4">
                    <div className="w-full bg-gray-200 rounded-full h-2.5 relative">
                        <div
                            className="bg-blue-500 h-2.5 rounded-full transition-all duration-300"
                            style={{ width: `${progress}%` }}
                        ></div>
                    </div>
                    <p className="text-center mt-2 text-sm text-gray-600">
                        Subiendo imágenes: {progress}%
                    </p>
                </div>
            )}

            {showError && (
                <div className="mt-4">
                    <p>Error al subir fotos intentalo de nuevo.</p>
                </div>
            )}

            {sizeError && (
                <div className="mt-4 text-red-600 text-sm">
                    {sizeError}
                </div>
            )}

            <div className="mt-6 grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                {other.map((item, index) => (
                    <div
                        key={index}
                        className="relative rounded-lg border-2 border-gray-300 overflow-hidden"
                    >
                        {item ? (
                            typeof item.image === "string" ? (
                                <img
                                    src={item.image}
                                    alt={`Foto ${index + 1}`}
                                    className="w-full h-40 object-cover"
                                />
                            ) : (
                                <img
                                    src={URL.createObjectURL(item)}
                                    alt={`Foto ${index + 1}`}
                                    className="w-full h-40 object-cover"
                                />
                            )
                        ) : (
                            <FontAwesomeIcon icon={faImage} size="2x" color={"#3E11B5"} />
                        )}
                        <button
                            onClick={() => borrarFoto(index, item)}
                            className="absolute top-2 right-2 bg-red-600 text-white p-1 rounded-full"
                        >
                            &times;
                        </button>
                    </div>
                ))}
            </div>

            <div className="w-full flex justify-between mt-6">
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-gray-500 hover:bg-gray-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={() => setStep(step - 1)}
                >
                    <FontAwesomeIcon icon={faArrowLeft} className="mr-2" />
                    Regresar
                </motion.button>
                <motion.button
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="flex items-center text-white bg-blue-500 hover:bg-blue-600 transition duration-300 px-6 py-3 rounded-full"
                    onClick={confirmPhotos}
                    disabled={isLoading}
                >
                    Continuar
                    <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </motion.button>
            </div>
        </div>
    );
};

export default UploadPhotosPt2;
