import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../services";
import { sendOtpUnfinished, getUnfinishedUser, fillUser } from "../services/auth.service";
import Navbar from "../components/header/Navbar";
import Footer from "../components/footer/Footer";
import Success from "../components/modals/modal-success";
import ErrorModal from "../components/modals/modal-error";
import { useDispatch } from 'react-redux';
import * as actions from "../redux/actions/auth-action";
import Spinner from "../components/Loader/Spinner";


const CompleteAccount = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [phone, setPhone] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [profilePicture, setProfilePicture] = useState(null);
    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [text, setText] = useState('');
    const [profilePicturePreview, setProfilePicturePreview] = useState(null);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [titleError, setTitleError] = useState(false);
    const [textError, setTextError] = useState(false);
    const [matchVerified, setMatchVerified] = useState({
        mayuscula: false,
        numero: false,
        especial: false,
        tamano: false,
    });
    const dispatch = useDispatch();


    const jwt = new URLSearchParams(location.search).get('t');

    const getUnfinishedUserInfo = async () => {

        setIsLoading(true);
        try {
            const response = await getUnfinishedUser(jwt);
            if (response.status === 200) {
                setPhone(response.data.phone);
            }
        }
        catch (error) {
            console.error(error);
        }
        finally {
            setIsLoading(false);
        }
    }

    useEffect(() => {
        if (jwt) {
            getUnfinishedUserInfo();
        }


    }, [jwt])

    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!verificationCode || !password || !profilePicture) {
            setError('Todos los campos son obligatorios.');
            return;
        }

        if (!matchVerified.mayuscula || !matchVerified.numero || !matchVerified.especial || !matchVerified.tamano) {
            setError('La contraseña no cumple con todos los requisitos.');
            return;
        }

        const newPhone = '+52' + phone;
        const formData = new FormData();
        formData.append('phone', newPhone);
        formData.append('code', verificationCode);
        formData.append('password', password);
        formData.append('profilePicture', profilePicture);

        setIsLoading(true);
        try {
            const response = await fillUser(formData, jwt);
            if (response.status === 200) {
                localStorage.setItem("token-yo-rento", response.data.token);

                localStorage.setItem(
                    "userData",
                    JSON.stringify({
                        token: response.data.token,
                        role: "USER",
                        id: Number(response.data.user?.id),
                        user: response.data?.user,
                    })
                );

                //localStorage.setItem('userData', JSON.stringify(response.data.user));

                dispatch(actions.loginSuccess({
                    role: "USER",
                    userId: response.data.user?.id,
                    user: response.data?.user,
                }, response.data.token));

                setText('Cuenta creada con éxito');
                setShowSuccessModal(true);
                setTimeout(() => {
                    setShowSuccessModal(false);
                    window.location = `/admin/chats?subTabView=3`
                }, 2000);

            }
        }
        catch (error) {
            console.error("Error al completar la cuenta", error);
            setTitleError("Error al Completar tu cuenta");
            setTextError("Favor de contactar a soporte técnico");
            setShowErrorModal(true);
        }
        finally {
            setIsLoading(false);
        }


    };


    const requestVerificationCode = async () => {
        const newPhone = '+52' + phone;

        if (!phone) {
            setError('Phone number is required to request a verification code.');
            return;
        }
        setIsLoading(true);
        try {
            const response = await sendOtpUnfinished(jwt, newPhone);
            if (response.status === 200) {
                setSuccessMessage(true);
            }
        }
        catch (error) {
            console.error("Error al enviar codigo de verificacion", error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const handleProfilePictureChange = (e) => {
        const file = e.target.files[0];
        setProfilePicture(file);
        if (file) {
            const previewUrl = URL.createObjectURL(file);
            setProfilePicturePreview(previewUrl);
        }
    };

    const verifiedRegexPassword = (value) => {
        setMatchVerified({
            mayuscula: value.match(/[A-Z]/g) !== null,
            numero: value.match(/[0-9]/g) !== null,
            especial: value.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/g) !== null,
            tamano: value.length > 7,
        });
    };

    const cerrarErrorModal = () => {
        setShowErrorModal(false);
    }

    return (
        <>
            <Navbar />
            <div className="p-6 max-w-md mx-auto bg-white rounded-lg shadow-md py-[4    rem]">
                <h1 className="text-2xl font-bold text-gray-800 text-center mb-6">Completa tu cuenta</h1>
                {error && <p className="text-red-600 text-sm text-center mb-4">{error}</p>}
                <form onSubmit={handleSubmit} className="space-y-4">

                    <div>
                        <label htmlFor="phone" className="block text-sm font-medium text-gray-700">
                            Telefono:
                        </label>
                        <input
                            id="phone"
                            type="number"
                            value={phone}
                            onChange={(e) => setPhone(e.target.value)}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>

                    <button
                        type="button"
                        onClick={requestVerificationCode}
                        className="w-full bg-purple-800 text-white py-2 rounded-lg hover:bg-indigo-700 focus:ring-2 focus:ring-indigo-500"
                        disabled={isLoading}
                    >
                        Enviar código de verificación
                    </button>
                    {successMessage && (<p className="text-green-500 text-center">Codigo envíado con éxito</p>)}

                    <div>
                        <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">
                            Ingresa el código de verificación:
                        </label>
                        <input
                            id="verificationCode"
                            type="text"
                            value={verificationCode}
                            onChange={(e) => setVerificationCode(e.target.value)}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />
                    </div>
                    <div>
                        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                            Crea tu contraseña:
                        </label>
                        <input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => {
                                const value = e.target.value;
                                setPassword(value);
                                verifiedRegexPassword(value);
                            }}
                            required
                            className="w-full px-3 py-2 border border-gray-300 rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500"
                        />

                        <div className="mt-2 text-sm text-gray-600">
                            <p className={matchVerified.mayuscula ? "text-green-600" : "text-red-600"}>
                                {matchVerified.mayuscula ? "✔" : "✖"} Al menos una letra mayúscula
                            </p>
                            <p className={matchVerified.numero ? "text-green-600" : "text-red-600"}>
                                {matchVerified.numero ? "✔" : "✖"} Al menos un número
                            </p>
                            <p className={matchVerified.especial ? "text-green-600" : "text-red-600"}>
                                {matchVerified.especial ? "✔" : "✖"} Al menos un carácter especial
                            </p>
                            <p className={matchVerified.tamano ? "text-green-600" : "text-red-600"}>
                                {matchVerified.tamano ? "✔" : "✖"} Al menos 8 caracteres
                            </p>
                        </div>

                    </div>
                    <div>
                        <label htmlFor="profilePicture" className="block text-sm font-medium text-gray-700">
                            Foto de perfil:
                        </label>
                        <input
                            id="profilePicture"
                            type="file"
                            accept="image/*"
                            onChange={handleProfilePictureChange}
                            required
                            className="w-full text-gray-600 file:mr-4 file:py-2 file:px-4 file:rounded-lg file:border-0 file:text-sm file:bg-purple-800 file:text-white hover:file:bg-indigo-700"
                        />
                        {profilePicturePreview && (
                            <div className="mt-4">
                                <p className="text-sm text-gray-600">Vista previa:</p>
                                <img
                                    src={profilePicturePreview}
                                    alt="Vista previa de la foto de perfil"
                                    className="w-24 h-24 object-cover rounded-full mx-auto"
                                />
                            </div>
                        )}
                    </div>
                    {isLoading ? (<div className="text-center justify-center"><Spinner /></div>) : (<button
                        type="submit"
                        className="w-full bg-cyan-500 text-white py-2 rounded-lg hover:bg-cyan-600 focus:ring-2 focus:ring-green-400"
                        disabled={isLoading}
                    >
                        Completar cuenta
                    </button>)}

                </form>
            </div>
            <Success show={showSuccessModal} text={text} />
            <ErrorModal show={showErrorModal} text={textError} title={titleError} onClose={cerrarErrorModal} />
            <Footer />
        </>
    );
}

export default CompleteAccount;

