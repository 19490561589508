import { faEye, faEyeSlash } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import RootLayout from "../../components/Layout";
import Breadcrumb from "../../components/breadcrumb";
import Button from "../../components/form/button";
import TextInput from "../../components/form/textinput";
import * as actions from "../../redux/actions/auth-action";
import { loginUser } from "../../services/auth.service";

const Login = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const onSubmit = async () => {
    try {

      const resp = await loginUser(phone, password);

      if (resp.data && resp.data.token) {
        localStorage.setItem("token-yo-rento", resp.data.token);
        localStorage.setItem(
          "userData",
          JSON.stringify({
            token: resp.data.token,
            role: "USER",
            id: resp.data.user?.userId,
            user: resp.data?.user,
          })
        );

        props.loginSuccess(
          {
            role: "USER",
            userId: resp.data.user?.userId,
            user: resp.data?.user,
          },
          resp.data.token
        );

        const user = resp.data.user || {};
        const roles = Array.isArray(user.roles) ? user.roles : [];

        const redirectPath = location.state?.from?.pathname || (roles.includes('lessor') ? "/admin/dashboard?modal-first=1" : "/");
        navigate(redirectPath, { replace: true });

      }
    } catch (e) {
      console.error('onSubmit error', e);
      props.loginFailed(e);
    }
  };

  return (
    <RootLayout>
      <div className="flex flex-col items-center min-h-screen pt-6">
        <div className="w-full max-w-md px-4 sm:px-0">
          <Breadcrumb
            options={[
              { name: "Inicio", link: "/", key: "inicio" },
              {
                name: "Iniciar sesión",
                link: "/signin",
                key: "iniciar-sesion",
              },
            ]}
          />
        </div>
        <motion.div
          className="w-full max-w-md bg-white shadow-2xl rounded-2xl p-8 mt-6"
          initial={{ opacity: 0, y: 50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          <StepEmail
            phone={phone}
            setPhone={setPhone}
            password={password}
            setPassword={setPassword}
            onSubmit={onSubmit}
            FailedRequest={Boolean(props.error)}
            clearAuthError={props.clearAuthError}
            {...props}
          />
        </motion.div>
      </div>
    </RootLayout>
  );
};

const StepEmail = ({
  phone,
  setPhone,
  password,
  setPassword,
  onSubmit,
  FailedRequest,
  clearAuthError,
}) => {
  const [securePass, setSecurePass] = useState(true);
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isValidPhone, setIsValidPhone] = useState("");
  const [canSubmit, setCanSubmit] = useState(false);
  const [inputError, setInputError] = useState("");
  const navigate = useNavigate();

  const validateInputs = () => {
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (phone.trim() === "") {
      return "";
    }

    if (phone.includes("@")) {
      if (!phone.match(emailRegex)) {
        return "error";
      }
    } else {
      if (!phone.match(phoneRegex)) {
        return "error";
      }
    }

    return "success";
  };

  const validatePass = () => {
    if (password.trim().length > 0) {
      return "success";
    }
    return "";
  };

  const trySubmit = (e) => {
    e.preventDefault();

    if (isValidPhone !== "success") {
      setInputError("Ingresa un correo o teléfono válido");
      return;
    }

    if (canSubmit && !isLoading) {
      setIsLoading(true);
      onSubmit()
        .finally(() => {
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    const res = validateInputs();
    const resPass = validatePass();

    setIsValidPhone(res);
    setCanSubmit(res === "success" && resPass === "success");
    setError(resPass);

    if (FailedRequest && (phone.trim() || password.trim())) {
      clearAuthError();
    }
  }, [phone, password]);

  return (
    <div className="px-6 sm:px-0">
      <h2 className="text-gray-800 text-xl font-semibold text-center mb-4">
        ¡Hola!
      </h2>
      <p className="text-center mb-8 text-gray-600">
        Te damos la bienvenida a la comunidad más segura para rentar y publicar
        tu inmueble
      </p>

      <div>
        <TextInput
          placeholder="Correo electrónico o teléfono"
          label="Identifícate"
          value={phone}
          onChange={(e) => {
            setPhone(e.target.value);
          }}
          disabled={isLoading}
          className="mb-4 rounded-full border-gray-100 focus:border-purple-600 focus:ring-purple-600"
          isValid={isValidPhone}
        />
        {inputError && !FailedRequest && (
          <div className="bg-red-100 border text-center border-red-400 text-red-700 px-4 py-2 rounded-full my-2">
            {inputError}
          </div>
        )}
        {FailedRequest && (
          <div className="bg-red-100 border text-center border-red-400 text-red-700 px-4 py-2 rounded-full my-2">
            Usuario o contraseña incorrectos. Por favor, inténtalo de nuevo.
          </div>
        )}

        <div className="relative mt-4">
          <label className="block mb-2 text-sm font-medium text-gray-900">Contraseña</label>
          <div className="relative">
            <TextInput
              placeholder="Contraseña"
              value={password}
              type={securePass ? "password" : "text"}
              disabled={isLoading}
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  trySubmit(e);
                }
              }}
              isValid={error}
              className="mb-4 rounded-full border-gray-300"
            />
            <FontAwesomeIcon
              icon={securePass ? faEye : faEyeSlash}
              className="absolute right-4 top-1/2 transform -translate-y-1/2 cursor-pointer text-gray-500"
              onClick={() => setSecurePass(!securePass)}
            />
          </div>
        </div>

        <div className="w-full mt-6">
          <Button
            onClick={trySubmit}
            disabled={!canSubmit || isLoading}
            className="w-full bg-indigo-600 text-white py-2 rounded-full hover:bg-indigo-700 transition duration-300"
          >
            {isLoading ? "Cargando..." : "Iniciar sesión"}
          </Button>

          <p
            onClick={() => navigate("/auth/forgot")}
            className="text-gray-500 text-sm text-center mt-4 cursor-pointer"
          >
            ¿Olvidaste tu contraseña?
          </p>
        </div>

        <div className="w-full mt-6">
          <Button
            onClick={() => navigate("/auth/register")}
            className="w-full bg-blue-500 text-white py-2 rounded-full hover:bg-blue-600 transition duration-300"
          >
            Crear cuenta
          </Button>
        </div>
      </div>
    </div >
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.auth.isLoggedIn,
  isLoading: state.auth.isLoading,
  error: state.auth.error,
});

const mapDispatchToProps = (dispatch) => ({
  loginSuccess: (data, token = null) => dispatch(actions.loginSuccess(data, token)),
  loginFailed: (error) => dispatch(actions.loginFailed(error)),
  clearAuthError: () => dispatch(actions.clearAuthError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
