import {
  faBuilding,
  faCheck,
  faCheckCircle,
  faCog,
  faComment,
  faFilter,
  faImage,
  faInbox,
  faPaperPlane,
  faTimes,
  faUser,
  faUserEdit
} from '@fortawesome/pro-light-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AnimatePresence, motion } from 'framer-motion';
import React, { useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import imgChatsVacios from '../../assets/drawe-chat-men.png';
import RootLayout from '../../components/Layout';
import { getMeUserInfo } from '../../services/auth.service';
import {
  getTags as fetchTagsFromAPI,
  getChatById,
  getChats,
  requestChatChangeStatus
} from '../../services/chats.service';
import Conversation from '../chats/conversation';
import GroupSwitch from './group-switch';
import axios from 'axios';
import { propertieMatch } from '../../services/properties.service';
import ChatsInProperty from './chats-in-property';
import Spinner from '../../components/Loader/Spinner';
import PhotoPropiedad from '../../components/modals/modal-photo-propiedad';
import Success from '../../components/modals/modal-success';
import Photo from '../../components/modals/modal-photo';

const TagSelector = ({ tags, selectedTags, setSelectedTags, isOpen, setIsOpen, isFilterActive, setIsFilterActive, setIsConfigActive, setMenuOpen }) => {

  const toggleTag = (tagId) => {
    setSelectedTags((prev) =>
      prev.includes(tagId)
        ? prev.filter((t) => t !== tagId)
        : [...prev, tagId]
    );
  };

  const handleFilterClick = () => {
    if (!isFilterActive) {
      setIsOpen(true);
      setIsFilterActive(true);
      setIsConfigActive(false);
      setMenuOpen(false);
    } else {
      handleCloseFilter();
    }
  };

  const handleCloseFilter = () => {
    setIsOpen(false);
    setIsFilterActive(false);
  };

  return (
    <div className="relative">
      <button
        onClick={handleFilterClick}
        className={`flex items-center gap-2 p-2 rounded-full ${isFilterActive ? 'bg-purple-800 text-white px-4' : ''}`}
      >
        <FontAwesomeIcon icon={isFilterActive ? faTimes : faFilter} className="cursor-pointer" />
        {isFilterActive && (
          <motion.span
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="text-white"
          >
            Filtrar
          </motion.span>
        )}
      </button>
      <AnimatePresence>
        {isOpen && (
          <motion.div
            initial={{ opacity: 0, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.9 }}
            className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md p-4 z-10"
          >
            <div className="flex justify-between items-center mb-2">
              <h3 className="text-sm font-semibold text-gray-700">Filtrar Tags</h3>
              <button onClick={handleCloseFilter} className="text-gray-500 hover:text-gray-700">
                Cerrar
              </button>
            </div>
            {tags.map((tag) => (
              <div
                key={tag.id}
                className="flex items-center gap-2 py-1 px-2 hover:bg-gray-100 rounded-md transition-colors"
              >
                <input
                  type="checkbox"
                  checked={selectedTags.includes(tag.id)}
                  onChange={() => toggleTag(tag.id)}
                  className="form-checkbox h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-2 focus:ring-blue-500"
                />
                <label style={{ color: `#${tag.color}` }} className="ml-2 text-sm font-medium">
                  {tag.name}
                </label>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

const useMediaQuery = (query) => {
  const [matches, setMatches] = useState(window.matchMedia(query).matches);

  useEffect(() => {
    const media = window.matchMedia(query);
    if (media.matches !== matches) {
      setMatches(media.matches);
    }
    const listener = () => setMatches(media.matches);
    media.addEventListener('change', listener);
    return () => media.removeEventListener('change', listener);
  }, [matches, query]);

  return matches;
};

const Chats = ({ token, user }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const initialSubTabView = Number(queryParams.get('subTabView')) || 1;
  const initialChatId = queryParams.get('chatId');
  const navigate = useNavigate();
  const [tabView, setTabView] = useState(4); // Default to Arrendador
  const [subTabView, setSubTabView] = useState(initialSubTabView); // Default sub-tab
  const [currentPage, setCurrentPage] = useState(1);
  const [totalChats, setTotalChats] = useState(0);
  const [chats, setChats] = useState([]);
  const [userInfo, setUserInfo] = useState({});
  const [selectedChat, setSelectedChat] = useState(null); // Estado para manejar el chat seleccionado
  const [menuOpen, setMenuOpen] = useState(false); // Estado para manejar el menú de configuración
  const [containerHeight, setContainerHeight] = useState('100vh'); // Estado para manejar la altura del contenedor
  const [innerContainerHeight, setInnerContainerHeight] = useState('100vh'); // Estado para manejar la altura del contenedor interno
  const [selectedTags, setSelectedTags] = useState([]); // Estado para manejar los tags seleccionados
  const [tags, setTags] = useState([]); // Estado para manejar los tags
  const [isFilterActive, setIsFilterActive] = useState(false); // Estado para manejar si el filtro está activo
  const [isConfigActive, setIsConfigActive] = useState(false); // Estado para manejar si la configuración está activa
  const pageSize = 100; // Número de elementos por página
  const [isSwitchActive, setIsSwitchActive] = useState(false); // estado para manejar si se agrupan por propiedad
  const [propiertiesInfo, setPropertiesInfo] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null); // estado para manejar la propiedad seleccionada.
  const { chatId } = useParams(); // Obtener el chatId de la URL
  const [rolSeleccionado, setRolSeleccionado] = useState('arrendador'); // Estado para manejar el rol seleccionado
  const [isLoading, setIsLoading] = useState(false); //Estado para manejar loading moments
  const chatsContainerRef = useRef(null); // Referencia al contenedor de chats
  const [showPhotoModal, setShowPhotoModal] = useState(false);
  const [text, setText] = useState('');
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [itemToModalId, setItemToModalId] = useState(null);
  const [pictureText, setPictureText] = useState('¿Deseas aceptar la solicitud de chat?');
  const [showPhotoOnlyModal, setShowPhotoModalOnly] = useState(false);

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const handleScroll = () => {
      if (
        chatsContainerRef.current &&
        chatsContainerRef.current.scrollTop + chatsContainerRef.current.clientHeight >= chatsContainerRef.current.scrollHeight
      ) {
        loadMoreChats();
      }
    };

    const container = chatsContainerRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, [chats]);

  const loadMoreChats = async () => {
    if (isLoading || (totalChats && chats.length >= totalChats)) return;

    setIsLoading(true);
    try {
      const skip = chats.length;
      const tagsQuery = selectedTags.length ? `&tagIds=${selectedTags.join(',')}` : '';

      let query = '';
      switch (subTabView) {
        case 1:
          query = `received?skip=${skip}&limit=${pageSize}&status=requested${tagsQuery}`;
          break;
        case 3:
          query = `sent?skip=${skip}&limit=${pageSize}${tagsQuery}`;
          break;
        default:
          query = `received?skip=${skip}&limit=${pageSize}&status=accepted${tagsQuery}`;
          break;
      }

      const respChats = await getChats(query, token);
      if (respChats) {
        setTotalChats(respChats.total);
        setChats((prevChats) => {
          if (prevChats.length === totalChats) {
            return prevChats;
          }
          return [...prevChats, ...respChats.chats]
        });
        //setChats(chats.concat(respChats.chats));
      }
    } catch (error) {
      console.error('Error al cargar más chats:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const respUserInfo = await getMeUserInfo(token);
      if (respUserInfo.data) {
        setUserInfo(respUserInfo.data.user);
        console.log("users desde userInfo", userInfo);
        if (respUserInfo.data.user.roles.length === 1 && respUserInfo.data.user.roles[0] === 'renter') {
          setTabView();
          setSubTabView(3);
          setTotalChats(0);
          setMenuOpen(false);
          handleCloseConfig();
          setRolSeleccionado('Inquilino');
        }
      }

      let query = '';
      const skip = (currentPage - 1) * pageSize;
      const tagsQuery = selectedTags.length ? `&tagIds=${selectedTags.join(',')}` : '';

      console.log('subTabView:', subTabView);


      switch (subTabView) {
        case 1:
          query = `received?skip=${skip}&limit=${pageSize}&status=requested${tagsQuery}`; // Solicitudes recibidas
          break;
        case 3:
          query = `sent?skip=${skip}&limit=${pageSize}${tagsQuery}`; // Solicitudes realizadas
          break;
        default:
          query = `received?skip=${skip}&limit=${pageSize}&status=accepted${tagsQuery}`; // Chats actuales
          break;
      }

      const respChats = await getChats(query, token);
      if (respChats) {
        setTotalChats(respChats.total);
        setChats(respChats.chats);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    const handleResize = () => {
      const navHeight = document.querySelector('nav')?.offsetHeight || 0;
      const paginationHeight = document.getElementById('pagination')?.offsetHeight || 0;
      setContainerHeight(`calc(100vh - ${navHeight}px)`);

      const activeUserHeight = document.getElementById('active-user')?.offsetHeight || 0;
      const tabsHeight = document.getElementById('tabs')?.offsetHeight || 0;
      setInnerContainerHeight(
        `calc(100vh - ${navHeight}px - ${activeUserHeight}px - ${tabsHeight}px - ${paginationHeight}px)`
      );
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    if (initialChatId) {

      const chatToSelect = chats.find(chat => chat.id === initialChatId);
      if (chatToSelect) {
        setSelectedChat(chatToSelect);
      }
    }
  }, [initialChatId, chats]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const resp = await fetchTagsFromAPI(token);
        if (resp.data.tags) {
          setTags(resp.data.tags);
        } else {
          setTags([]);
        }
      } catch (error) {
        console.error('Error fetching tags:', error);
        setTags([]);
      }
    };

    fetchTags();
  }, [token]);

  useEffect(() => {
    const fetchChatsOrSingleChat = async () => {
      try {
        if (chatId) {
          // Si hay un chatId, obtener el chat completo desde el servidor
          const chat = await getChatById(chatId, token);
          if (chat) {
            console.log(chat);

            setSelectedChat(chat.chat); // Establecer el chat seleccionado
          }
        }
      } catch (error) {
        console.error('Error fetching chat:', error);
      }
    };

    fetchChatsOrSingleChat();
  }, [chatId, token]);

  useEffect(() => {

    const fetchPropertiesData = async () => {
      try {
        const url = "/app/chats/conversations/properties?skip=0&limit=10";
        const respProperty = await axios.get(url, {
          headers: { Authorization: `Bearer ${token}` },
        });
        if (respProperty.status === 200) {
          setPropertiesInfo(respProperty.data.properties);
        }
      }
      catch (error) {
        console.error('Error fetching Properties data:', error);
      }
    };
    fetchPropertiesData();
    fetchData();
  }, [currentPage, subTabView, token, selectedTags]);


  const handleChatClickGrouped = (property) => {
    setSelectedProperty(property);
  };

  const onVerifySelfie = async (item) => {
    try {
      setIsLoading(true)
      const resp = await getMeUserInfo();
      if (resp.data?.user?.profile_picture !== null) {
        handleAccept(item.id)
        loadMoreChats();
      }
      else {
        setShowPhotoModal(true);
        setItemToModalId(item.id);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setIsLoading(false)
    }
  };

  const onVerifySelfieFormContact = async (item) => {
    try {
      setIsLoading(true)
      const resp = await getMeUserInfo();
      if (resp.data?.user?.profile_picture !== null) {
        loadMoreChats();
        return true;
      }
      else {
        setPictureText('¿Deseas abrir este chat?');
        setShowPhotoModalOnly(true);
        setItemToModalId(item.id);
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
    finally {
      setIsLoading(false)
    }
  }

  const handleChatClick = async (chat) => {

    if (chat.chat_status !== 'accepted') {
      return;
    }


    const canSelectChat = await onVerifySelfieFormContact(chat);

    if (canSelectChat) {
      setSelectedChat(chat);
    }
    else {
      console.log("no tienes foto no puedes");
    }

  };

  const closePhotoModal = () => {
    setShowPhotoModal(false);
  }

  const closePhotoModalOnly = () => {
    setShowPhotoModalOnly(false);
  }

  const handleAccept = async (itemId) => {
    try {
      const resp = await requestChatChangeStatus(itemId, { accept: true }, token); // Pasar token
      if (resp) {
        const updatedChats = chats.map((chat) => {
          if (chat._id === itemId) {
            return { ...chat, chat_status: 'accepted' };
          }
          return chat;
        });
        setChats(updatedChats);
        setText('Has aceptado la invitación.');
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000)
        setTimeout(() => {
          window.location = `/admin/chats?subTabView=2&chatId=${itemToModalId}`
        }, 1500)
      } else {
        setText('No se pudo aceptar la invitación.');
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000)
      }
    } catch (error) {
      console.error('Error al aceptar la invitación:', error);
      setText('Error al aceptar la invitación.');
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1000)
    }
  };

  const handleReject = async (item) => {
    const itemId = item.id;

    console.log('Rechazar:', itemId, item);

    try {
      const resp = await requestChatChangeStatus(itemId, { accept: false }, token); // Pasar token
      console.log('Respuesta:', resp);

      if (resp) {
        const updatedChats = chats.filter((chat) => chat._id !== itemId);
        setChats(updatedChats);
        setText('Has rechazado la invitación.');
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000)
      } else {
        setText('No se pudo rechazar la invitación.');
        setShowSuccessModal(true);
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 1000)
      }
    } catch (error) {
      console.error('Error al rechazar la invitación:', error);
      setText('Error al rechazar la invitación.');
      setShowSuccessModal(true);
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 1000)
    }
  };

  const handleConfigClick = () => {
    if (!isConfigActive) {
      setMenuOpen(true);
      setIsConfigActive(true);
      setIsFilterActive(false);
    } else {
      handleCloseConfig();
    }
  };

  const handleCloseConfig = () => {
    setMenuOpen(false);
    setIsConfigActive(false);
  };

  const handleSwitchChange = (nextChecked) => {
    setIsSwitchActive(nextChecked);
  }

  const renderTabs = () => (
    <div id="tabs">
      {tabView === 4 && (
        <div className="flex justify-around p-4">
          <button
            className={`flex w-full justify-center items-center gap-2 pb-2 ${subTabView === 1
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500'
              }`}
            onClick={() => {
              setSubTabView(1);
              setCurrentPage(1);
              setChats([]);
              setTotalChats(0);
            }}
          >
            <FontAwesomeIcon icon={faInbox} />
            <span>Solicitudes recibidas</span>
          </button>
          <button
            className={`flex w-full justify-center items-center gap-2 pb-2 ${subTabView === 2
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500'
              }`}
            onClick={() => {
              setSubTabView(2);
              setTotalChats(0);
              setCurrentPage(1);
              setChats([]);
            }}
          >
            <FontAwesomeIcon icon={faComment} />
            <span>Mis Conversaciones</span>
          </button>
        </div>
      )}

      {tabView === 5 && (
        <div className="flex justify-around border-b mb-4 p-4">

          <button
            className={`flex items-center gap-2 pb-2 ${subTabView === 3
              ? 'text-blue-500 border-b-2 border-blue-500'
              : 'text-gray-500'
              }`}
            onClick={() => {
              setSubTabView(3);
              setCurrentPage(1);
              setChats([]);
              setTotalChats(0);
            }}
          >
            <FontAwesomeIcon icon={faPaperPlane} />
            <span>Solicitudes realizadas</span>
          </button>
        </div>
      )}
    </div>
  );

  const renderChats = () => (
    <div ref={chatsContainerRef} className="p-4 overflow-y-auto" style={{ height: innerContainerHeight }}>
      {subTabView === 2 && tabView === 4 && (<div className='flex p-1'><p className='pr-2'> Agrupar</p><GroupSwitch checked={isSwitchActive} onSwitchChange={handleSwitchChange} /></div>)}
      {((subTabView === 1 || subTabView === 2 || subTabView === 3) && isSwitchActive === false) && (
        <div className="chats space-y-2">
          {isLoading ? (
            <div className="flex items-center justify-center w-full">
              <Spinner />
            </div>
          ) : null}
          {chats.map((item, index) => (
            <div
              key={index}
              className="chat-item border-b border-gray-200 p-3 hover:bg-gray-100 cursor-pointer flex items-center justify-between"
              onClick={() => handleChatClick(item)}
            >
              <div className="flex items-center gap-4 max-w-[70%]">
                <div className="flex-shrink-0 w-12 h-12 relative rounded-full overflow-hidden">
                  {item.profile_picture ? (
                    <img
                      src={item.profile_picture}
                      alt="User Profile"
                      className="w-full h-full object-cover"
                    />
                  ) : (
                    <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-500">
                      <FontAwesomeIcon icon={faUser} />
                    </div>
                  )}
                  {item.investigation_done && (
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="absolute bottom-0 right-0 w-4 h-4 text-green-500"
                    />
                  )}
                </div>
                <div className="flex flex-col max-w-[100%] pe-4">
                  <h3 className="text-blue-500 text-lg font-medium truncate" title={item.user}>{item.user}</h3>
                  <div className="flex items-center gap-1 text-gray-500 text-sm truncate" title={item.property}>
                    <FontAwesomeIcon icon={faBuilding} className="text-gray-500" />
                    <span className="truncate">{item.property}</span>
                  </div>
                  <div className="flex flex-wrap justify-between items-center gap-2 mt-2">
                    {item.message_type === 'image' ? (
                      <div className="flex items-center text-gray-600 text-sm">
                        <FontAwesomeIcon icon={faImage} className="mr-2" />
                        <span>[Imagen]</span>
                      </div>
                    ) : (
                      <div className="flex items-center text-gray-600 text-sm truncate" title={item.message}>
                        <FontAwesomeIcon
                          icon={faComment}
                          className={`mr-2 ${item.chat_status === 'requested'
                            ? 'text-orange-500'
                            : item.chat_status === 'accepted'
                              ? 'text-green-500'
                              : 'text-gray-500'
                            }`}
                        />
                        <span className="truncate">
                          {item.owner_id === userInfo.id && (
                            item.chat_status === 'accepted' ? (
                              item.message || 'Haz aceptado la solicitud de mensaje'
                            ) : item.chat_status === 'requested' ? (
                              item.message || 'Te ha enviado una invitación a chatear'
                            ) : null
                          )}
                          {item.user_id === userInfo.id && (
                            item.chat_status === 'accepted' ? (
                              item.message || 'Ha aceptado tu solicitud de mensaje'
                            ) : item.chat_status === 'requested' ? (
                              item.message || 'Haz enviado una invitación a chatear'
                            ) : null
                          )}
                        </span>
                      </div>
                    )}
                    {subTabView === 1 && (
                      <div className="flex mx-auto items-center gap-2 mt-2">
                        <button
                          onClick={(e) => {
                            onVerifySelfie(item)
                            e.preventDefault();
                            e.stopPropagation();
                          }}
                          className="bg-green-500 text-white px-3 py-2 rounded-full flex items-center gap-1"
                        >
                          <FontAwesomeIcon icon={faCheck} />
                          Aceptar
                        </button>
                        <button
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            handleReject(item);
                          }
                          }
                          className="bg-red-500 text-white px-3 py-2 rounded-full flex items-center gap-1"
                        >
                          <FontAwesomeIcon icon={faTimes} />
                          Rechazar
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col items-end gap-1">
                <span className={`text-sm align-top ${item.unread_messages > 0 ? 'text-green-500' : 'text-gray-500'}`}>
                  {new Date(item.last_message).toLocaleDateString() ===
                    new Date().toLocaleDateString()
                    ? new Date(item.last_message).toLocaleTimeString()
                    : new Date(item.last_message).toLocaleDateString()}
                </span>
                {item.unread_messages > 0 && (
                  <span className="bg-green-500 text-white rounded-full px-2 py-1 text-xs self-center">
                    {item.unread_messages}
                  </span>
                )}

              </div>
            </div>
          ))}
        </div>
      )
      }
      {(subTabView === 2 && tabView === 4 && isSwitchActive === true) && (
        <div className="flex overflow-y-auto space-x-4">
          {selectedProperty !== null && (<ChatsInProperty property={selectedProperty} backToProperties={() => setSelectedProperty(null)} handleChatClick={handleChatClick} />)}
          {selectedProperty === null && (<div className="flex flex-col space-y-4 min-w-[300px] flex-grow">
            {propiertiesInfo.map((property, index) => (
              <div
                key={index}
                className="bg-white border-b-2 w-full overflow-hidden"
              >
                <div
                  className="flex items-center p-3 hover:bg-gray-100 cursor-pointer"
                  onClick={() => handleChatClickGrouped(property)}
                >
                  <div className="flex-shrink-0 w-12 h-12 relative rounded-full overflow-hidden">
                    {property.image ? (
                      <img
                        src={property.image}
                        alt="User Profile"
                        className="w-full h-full object-cover"
                      />
                    ) : (
                      <div className="flex items-center justify-center w-full h-full bg-gray-200 text-gray-500">
                        <FontAwesomeIcon icon={faUser} />
                      </div>
                    )}
                    {property.investigation_done && (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="absolute bottom-0 right-0 w-4 h-4 text-green-500"
                      />
                    )}
                  </div>
                  <div className="ml-4 flex flex-col flex-grow overflow-hidden">
                    <h3 className="text-lg font-medium text-blue-500 break-words" title={property.title}>
                      {property.title}
                    </h3>
                    <h2>Conversaciones: {property.chats}</h2>
                    <span className={`text-sm ${property.chats > 0 ? 'text-black' : 'text-black'}`}>
                      <p>Ultima Actividad: </p>
                      {new Date(property.last_activity).toLocaleDateString() === new Date().toLocaleDateString()
                        ? new Date(property.last_activity).toLocaleTimeString()
                        : new Date(property.last_activity).toLocaleDateString()}
                    </span>
                  </div>

                  <div className="flex flex-col items-end gap-1 p-3">
                    {property.unread_messages > 0 && (
                      <span className="bg-red-500 text-white rounded-full px-2 py-1 text-xs">
                        {property.unread_messages}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>)}
        </div>

      )}
    </div>
  );

  const renderActiveUser = () => (
    <div id="active-user" className="flex items-center justify-between p-6">
      <div className="flex items-center gap-4">
        <div className="rounded-full overflow-hidden w-12 h-12 flex-shrink-0">
          <img
            src={userInfo.profile_picture || 'default_image_url'}
            alt="User Profile"
            className="w-full h-full object-cover"
          />
        </div>
        <div className="flex flex-col items-start justify-start">
          <h2 className="text-xl font-semibold text-gray-700">{userInfo.name}</h2>
          <span className="bg-green-200 text-green-600 w-content px-3 py-1 rounded-full text-xs">
            Activo
          </span>
        </div>
      </div>
      <div className="relative flex items-center gap-2">
        {rolSeleccionado === 'arrendador' && (
          <TagSelector tags={tags} selectedTags={selectedTags} setSelectedTags={setSelectedTags} isOpen={isFilterActive} setIsOpen={setIsFilterActive} isFilterActive={isFilterActive} setIsFilterActive={setIsFilterActive} setMenuOpen={setMenuOpen} setIsConfigActive={setIsConfigActive} />
        )}
        <div className="relative">

          <button
            onClick={handleConfigClick}
            className={`relative flex items-center gap-2 p-2 rounded-full ${isConfigActive ? 'bg-purple-800 text-white px-4' : ''}`}
          >
            <FontAwesomeIcon icon={isConfigActive ? faTimes : faCog} className="cursor-pointer" />
            {isConfigActive && (
              <motion.span
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className="text-white"
              >
                Rol
              </motion.span>
            )}
          </button>
          <AnimatePresence>
            {menuOpen && (
              <motion.div
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.9 }}
                className="absolute mt-2 right-0 bg-white shadow-lg rounded-md p-4 z-10 w-36"
              >
                <div
                  className="flex items-center gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded"
                  onClick={() => {
                    setTabView(4);
                    setSubTabView(1);
                    setTotalChats(0);
                    setMenuOpen(false);
                    handleCloseConfig();
                    setRolSeleccionado('arrendador');
                  }}
                >
                  <FontAwesomeIcon icon={faUserEdit} className="text-gray-600" />
                  <span
                    className={`text-sm font-semibold ${tabView === 4 ? 'text-blue-500' : 'text-gray-500'
                      }`}
                  >
                    Arrendador
                  </span>
                </div>
                <div
                  className="flex items-center gap-2 cursor-pointer p-2 hover:bg-gray-100 rounded"
                  onClick={() => {
                    setTabView();
                    setSubTabView(3);
                    setTotalChats(0);
                    setMenuOpen(false);
                    handleCloseConfig();
                    setRolSeleccionado('Inquilino');
                  }}
                >
                  <FontAwesomeIcon icon={faUser} className="text-gray-600" />
                  <span
                    className={`text-sm font-semibold ${tabView === 5 ? 'text-blue-500' : 'text-gray-500'
                      }`}
                  >
                    Inquilino
                  </span>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
    </div>
  );

  const handleChatUpdate = (updatedChat) => {
    setSelectedChat(updatedChat); // Actualiza el chat seleccionado
  };

  return (
    <RootLayout admin>
      <div className="flex flex-col md:flex-row" style={{ height: containerHeight }}>
        <AnimatePresence initial={false}>
          {selectedChat && isMobile ? (
            <motion.div
              key="chat"
              initial={{ x: '100%' }}
              animate={{ x: 0 }}
              exit={{ x: '100%' }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-2/3 flex flex-col"
            >
              <Conversation chat={selectedChat} token={token} user={user} handleBackClick={() => setSelectedChat(null)} onChatUpdate={handleChatUpdate} subTabView={subTabView} />
            </motion.div>
          ) : (
            <motion.div
              key="chats"
              initial={{ x: 0 }}
              animate={{ x: 0 }}
              exit={{ x: 0 }}
              transition={{ duration: 0.3 }}
              className="w-full md:w-1/3"
            >
              {renderActiveUser()}
              {renderTabs()}
              {renderChats()}
            </motion.div>
          )}
        </AnimatePresence>

        <div className={`w-full ${isMobile && selectedChat ? 'hidden' : 'md:block md:w-2/3'}`}>
          {selectedChat ? (
            <Conversation
              chat={selectedChat}
              token={token}
              user={user}
              handleBackClick={() => setSelectedChat(null)}
              onChatUpdate={handleChatUpdate}
              subTabView={subTabView}
            />
          ) : (
            !isMobile && (
              <div className="w-full h-full bg-gray-100 rounded-xl flex flex-col items-center justify-center">
                <img src={imgChatsVacios} alt="Sin chats" className="w-full md:w-48 object-cover" />
                Selecciona un chat para ver la conversación
              </div>
            )
          )}
        </div>

      </div>
      {/* aqui es donde hay que cambiarlo */}
      <PhotoPropiedad isOpen={showPhotoModal} onClose={closePhotoModal} onSuccess={() => {
        setShowPhotoModal(false);
        handleAccept(itemToModalId);
        {/*fetchData();*/ }

      }} text={pictureText} />
      <Success show={showSuccessModal} text={text} />
      <Photo isOpen={showPhotoOnlyModal} onClose={closePhotoModalOnly} onSuccess={() => {
        fetchData();
      }} />
    </RootLayout>
  );
};

const mapStateToProps = (state) => ({
  token: state.auth.token,
  user: state.auth.userData
});

export default connect(mapStateToProps)(Chats);
