import { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import Spinner from '../../components/Loader/Spinner';
import { getInvestigations, getInvestigationDetails } from '../../services/investigations.service';
import { getTruoraLink, getTruoraStatus } from '../../services/auth.service';
import DefaultImage from '../../assets/drawe-confirm-upload.png';
import ConfirmUpload from '../../assets/drawe-confirm-upload.png';
import Pagination from '../../utils/Pagination';
import InvestigationPaymentForm from './InvestigationPaymentForm';
import FormularioDinamico from './Questions';
import DocumentSection from '../../utils/DocumentSection';

const fadeUpVariants = {
    hidden: { opacity: 0, y: 20 },
    visible: { opacity: 1, y: 0 },
    exit: { opacity: 0, y: 20 }
};

const steps = {
    payment: 'payment',
    truora: 'truora',
    details: 'details',
};

const MyInvestigations = () => {
    const [investigations, setInvestigations] = useState([]);
    const [selectedInvestigation, setSelectedInvestigation] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [isDetailLoading, setIsDetailLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalInvestigations, setTotalInvestigations] = useState(1);
    const [step, setStep] = useState(null);
    const [url, setUrl] = useState('');
    const [hasPaid, setHasPaid] = useState(false);
    const [form, setForm] = useState(null);
    const [formResponses, setFormResponses] = useState({});
    const [isDocumentsCollapsed, setIsDocumentsCollapsed] = useState(false);
    const [isFormCollapsed, setIsFormCollapsed] = useState(false);

    useEffect(() => {
        const fetchInvestigations = async () => {
            setIsLoading(true);
            try {
                const response = await getInvestigations(`limit=3&skip=${(currentPage - 1) * 3}`);
                if (response.status === 200) {
                    setInvestigations(response.data.investigations);
                    setTotalInvestigations(response.data.total);
                }
            } catch (error) {
                console.error("Error al obtener las investigaciones", error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchInvestigations();
    }, [currentPage]);

    useEffect(() => {
        const getLinkTruora = async () => {
            try {
                const response = await getTruoraLink();
                if (response.status === 200) {
                    setUrl(response.data.link);
                }
            } catch (error) {
                console.error("Error al obtener el link de truora", error);
            }
        };

        if (step === steps.truora) {
            setTimeout(() => {
                getLinkTruora();
            }, 1000);
        }
    }, [step]);

    const handleInvestigationClick = async (id) => {
        const selected = investigations.find((inv) => inv.id === id);
        setSelectedInvestigation(selected);

        if (selected?.investigation_paid === false) {
            setStep(steps.payment);
        } else if (selected.investigation_paid && selected.identity_verification.verified === false) {
            setSelectedInvestigation(selected);
            setStep(steps.truora);
        } else {
            setSelectedInvestigation(selected);
            await fetchInvestigationDetails(id);
        }
    };


    const handlePaymentConfirmed = () => {

        setStep(steps.truora);
    };

    const fetchInvestigationDetails = async (id) => {
        setIsDetailLoading(true);
        try {
            const response = await getInvestigationDetails(id);
            if (response.status === 200) {
                setSelectedInvestigation(response.data);
                setStep(steps.details);
                setForm(response.data.form);
            }
        } catch (error) {
            console.error("Error al obtener los detalles de la investigación", error);
        } finally {
            setIsDetailLoading(false);
        }
    };



    const handleBackToList = () => {
        setSelectedInvestigation(null);
        setStep(null);
    };


    useEffect(() => {
        if (step !== steps.truora) return;
        console.log(step);

        const getInitialStatus = async () => {
            try {
                const response = await getTruoraStatus();
                if (response.status === 200 && response.data.status === 'success') {
                    setStep(steps.details);
                    console.log("selectedinv", selectedInvestigation);
                    fetchInvestigationDetails(selectedInvestigation?.id);
                }
            } catch (error) {
                console.error("Error al obtener el status de truora", error);
            }
        };

        getInitialStatus();
        const intervalId = setInterval(getInitialStatus, 5000);

        return () => clearInterval(intervalId);
    }, [step, selectedInvestigation]);

    const getStatusClass = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'text-orange-500 font-semibold';
            case 'inprogress':
                return 'text-yellow-500 font-semibold';
            case 'success':
                return 'text-green-500 font-semibold';
            default:
                return 'text-gray-500';
        }
    };

    const getStatusText = (status) => {
        switch (status.toLowerCase()) {
            case 'pending':
                return 'Pendiente';
            case 'inprogress':
                return 'En progreso';
            case 'success':
                return 'Éxito';
            default:
                return status;
        }
    };


    if (isLoading) {
        return (
            <div className="flex items-center justify-center p-4">
                <Spinner />
            </div>
        );
    }

    return (
        <div>
            <h1 className="text-[24px] text-purple-800 text-center font-semibold mb-8">
                Mis Investigaciones
            </h1>
            <motion.div
                className="p-6"
                variants={fadeUpVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
            >
                {!selectedInvestigation && step === null ? (
                    investigations.length > 0 ? (
                        <div className="space-y-4">
                            {investigations.map((investigation) => (
                                <motion.div
                                    key={investigation.id}
                                    className="w-full p-4 flex flex-col lg:flex-row justify-between items-center gap-4 my-4 bg-white rounded-xl shadow-md hover:shadow-lg transition-shadow duration-200"
                                    onClick={() => handleInvestigationClick(investigation.id)}
                                >
                                    <div className="w-full flex flex-col lg:flex-row gap-4 max-w-full lg:max-w-[85%]">
                                        <motion.div
                                            className="relative w-full max-w-full lg:max-w-[190px] overflow-hidden rounded-xl shadow-sm"
                                            whileHover={{ scale: 1.02 }}
                                        >
                                            <img
                                                src={DefaultImage}
                                                alt="Investigación"
                                                className="w-full h-36 object-cover rounded-xl"
                                            />
                                        </motion.div>
                                        <div>
                                            <h3 className="text-lg font-semibold text-gray-800">{investigation.title}</h3>
                                            <span className={`text-sm block mb-1 ${getStatusClass(investigation.status)}`}>
                                                Estatus: {getStatusText(investigation.status)}
                                            </span>
                                            <p className="text-gray-700 text-sm">
                                                Fecha de creación: {new Date(investigation.created_at).toLocaleDateString()}
                                            </p>
                                        </div>
                                    </div>
                                    <div className="flex flex-col items-center mt-4 lg:mt-0">
                                        <motion.button
                                            className="bg-purple-800 text-white py-2 px-4 rounded-xl hover:bg-purple-600 transition-colors duration-200"
                                            onClick={(e) => {
                                                e.stopPropagation();
                                                handleInvestigationClick(investigation.id);
                                            }}
                                        >
                                            Ver detalles
                                        </motion.button>
                                    </div>
                                </motion.div>
                            ))}
                            <div className="p-4 flex items-end justify-end">
                                <Pagination
                                    currentPage={currentPage}
                                    totalCount={totalInvestigations}
                                    pageSize={3}
                                    onPageChange={(page) => setCurrentPage(page)}
                                />
                            </div>
                        </div>
                    ) : (
                        <div className="flex flex-col items-center text-center">
                            <img src={DefaultImage} alt="Sin investigaciones" className="w-1/2 mb-4" />
                            <p className="text-gray-700">
                                No tienes investigaciones disponibles.
                            </p>
                        </div>
                    )
                ) : step === steps.payment ? (
                    <motion.div
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        <div>
                            <InvestigationPaymentForm onPaymentConfirmed={handlePaymentConfirmed} />
                        </div>
                    </motion.div>
                ) : step === steps.truora ? (
                    <div className="flex flex-col justify-center text-center">
                        <button
                            onClick={() => window.open(url, "_blank")}
                            className="mt-2 px-4 py-2 bg-purple-800 text-white rounded-md hover:bg-purple-600 transition text-2xl"
                        >
                            Ir a verificación de identidad
                        </button>
                        <div className="flex justify-center items-center mt-4">
                            <img className="text-center" src={ConfirmUpload} alt="Confirm Upload" />
                        </div>
                    </div>

                ) : (
                    <motion.div
                        variants={fadeUpVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                    >
                        {isDetailLoading ? (
                            <div className="flex items-center justify-center p-4">
                                <Spinner />
                            </div>
                        ) : (
                            <div>
                                <h3 className="text-2xl font-semibold text-gray-800 mb-6">
                                    Detalles de la Investigación # {selectedInvestigation.id}
                                </h3>
                                <p className={`text-sm block mb-4 ${getStatusClass(selectedInvestigation.status)}`}>
                                    Estatus: {getStatusText(selectedInvestigation.status)}
                                </p>

                                <div className="mb-4">
                                    <button
                                        onClick={() => setIsDocumentsCollapsed(!isDocumentsCollapsed)}
                                        className="px-4 py-2 bg-purple-800 text-white rounded-md hover:bg-purple-600 transition w-full"
                                    >
                                        {isDocumentsCollapsed ? 'Ocultar Documentos' : 'Ver Documentos'}
                                    </button>
                                    <DocumentSection
                                        selectedInvestigation={selectedInvestigation}
                                        isDocumentsCollapsed={isDocumentsCollapsed}
                                    />
                                </div>

                                <div>
                                    <button
                                        onClick={() => setIsFormCollapsed(!isFormCollapsed)}
                                        className="px-4 py-2 bg-purple-800 text-white rounded-md hover:bg-purple-600 transition w-full"
                                    >
                                        {isFormCollapsed ? 'Ocultar Formulario' : 'Ver Formulario'}
                                    </button>
                                    {isFormCollapsed && (
                                        <div className="mt-4">
                                            {form ? (
                                                <FormularioDinamico invForm={form} />
                                            ) : (
                                                <p className="text-gray-500">Cargando formulario...</p>
                                            )}
                                        </div>
                                    )}
                                </div>

                                <button
                                    onClick={handleBackToList}
                                    className="mt-8 px-4 py-2 bg-purple-800 text-white rounded-md hover:bg-purple-600 transition"
                                >
                                    Volver a la lista
                                </button>
                            </div>
                        )}
                    </motion.div>
                )}
            </motion.div>
        </div>
    );
};

export default MyInvestigations;
