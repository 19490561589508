import { faCalendarAlt, faIdBadge, faMapLocationDot, faUser, faPaw, faUserGroup, faPeopleGroup, faHashtag, faCircleInfo, faSignature, faPlus } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { useOutletContext, Link, useLocation } from "react-router-dom";
import moment from "moment";
import Spinner from "../../components/Loader/Spinner";
import axios from "axios";
import { getMeUserInfo } from "../../services/auth.service";
import ModalPassport from "../../components/modals/modal-passport";
import { connect } from "react-redux";
import { updateUserData } from '../../redux/actions/auth-action';
import { useDispatch } from 'react-redux';
import Success from "../../components/modals/modal-success";
import { useNavigate } from "react-router-dom";


const CompletePassport = (props) => {


    const userInfo = props.user.user;
    const token = useOutletContext().token;

    if (!userInfo) {
        return <p>Cargando información...</p>;
    }

    const [name, setName] = useState(userInfo.name);
    const [lastName1, setLastName1] = useState(userInfo.last_name1);
    const [lastName2, setLastName2] = useState(userInfo.last_name2);
    const [curp, setCurp] = useState(userInfo.curp);
    const [birthDate, setBirth_Date] = useState(moment(userInfo.birth_date).format("YYYY-MM-DD"));
    const [isLoading, setIsLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const [hasCompanions, setHasCompanions] = useState(userInfo.has_companions);
    const [tripReason, setTripReason] = useState(userInfo.trip_reason);
    const [presentation, setPresentation] = useState(userInfo.presentation);

    //condicionales
    const [tenants, setTenants] = useState(userInfo.tenants);
    const [companions, setCompanions] = useState(userInfo.companions);
    const [minors, setMinors] = useState(userInfo.minors);
    const [hasPets, setHasPets] = useState(userInfo.has_pets);
    const [jobSituation, setJobSituacion] = useState(userInfo.job_situation);
    const [userInfoo, setUserInfoo] = useState({});
    const [hasPaid, setHasPaid] = useState(false);
    const [showModal, setShowModal] = useState('');
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [text, setText] = useState('');

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const validateCURP = (curp) => {
        const curpPattern = /^[A-Z]{1}[AEIOU]{1}[A-Z]{2}\d{2}(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])[HM](AS|BC|BS|CC|CL|CM|CS|CH|DF|DG|GT|GR|HG|JC|MC|MN|MS|NL|NT|OC|PL|QT|QR|SP|SL|SR|TC|TS|TL|VZ|YN|ZS)[B-DF-HJ-NP-TV-Z]{3}[A-Z0-9]{1}\d{1}$/;
        return curpPattern.test(curp);
    };

    const updateUser = async () => {

        setIsLoading(true);
        setSuccessMessage("");
        setErrorMessage("");

        if (!validateCURP(curp)) {
            setErrorMessage("CURP debe tener 18 caracteres y seguir el formato correcto.");
            setIsLoading(false);
            return;
        }

        try {

            const data = {
                name,
                lastName1: lastName1,
                lastName2: lastName2,
                curp,
                birthDate: birthDate,
                hasCompanions: hasCompanions,
                tripReason: tripReason,
                presentation,
                tenants: parseInt(tenants, 10),
                companions,
                hasPets: hasPets,
                jobSituation: jobSituation,
            };
            const response = await axios.put('/app/core/users',

                data,
                {
                    headers: {
                        'Content-Type': 'application/json',
                        Authorization: `Bearer ${token}`
                    },
                });

            if (response.status === 200) {
                const updatedUserData = response.data;
                dispatch(updateUserData(updatedUserData));
                setText("PASEGURO completado.");
                setShowSuccessModal(true);
                setTimeout(() => {
                    setShowSuccessModal(false);
                    navigate("/admin/personal-panel/data-personal");
                }, 2000)

            }

        } catch (error) {
            console.error('Failed to update user:', error);
        } finally {
            setIsLoading(false);
        }
    };


    const handleClick = () => {
        updateUser();
    }

    const handleSelectChange = (event) => {
        setSeeAditionalSelects(event.target.value === 'Sí');
    }

    const getMyInfo = async (checkModal = true) => {
        const urlParams = new URLSearchParams(window.location.search);
        if (checkModal) {
            const modal = urlParams.get("modal-passport");
            if (modal) setShowModal(true);
        }
        setIsLoading(true);
        try {
            const resp = await getMeUserInfo(token);
            if (resp.data) {
                setUserInfoo(resp.data.user);
                setHasPaid(resp.data.user.identity_verification_paid);
            }
        }
        catch (e) {
            console.error("Error al traer userInfo", error);
        }
        finally {
            setIsLoading(false);
        }
    };

    const closeModal = () => {
        setShowModal(false);
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.delete("modal-passport");
        window.history.replaceState(null, "", `${window.location.pathname}?${urlParams}`);
        getMyInfo(false);
    };


    useEffect(() => {

        getMyInfo();

    }, []);

    return (

        <div className="bg-white p-6">
            <ModalPassport show={showModal} onConfirm={closeModal} />
            <div className="flex gap-5 mb-4">
                <h3 className="text-2xl font-semibold text-gray-800 mb-6">
                    PASE-GURO
                </h3>
            </div>
            <div className="flex gap-5 mb-4">
                <h4 className="text-2xl text-gray-800 mb-6">
                    Informacion personal
                </h4>

                {!hasPaid && !isLoading && (
                    <Link
                        to="/admin/personal-panel/passport-payment-form"
                        className="bg-purple-100 text-purple-800 px-3 py-1 rounded-full flex items-center mb-6">
                        <FontAwesomeIcon icon={faPlus} className="mr-1" />&nbsp;
                        Completar PASE-GURO
                    </Link>
                )}

            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">

                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faUser} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Nombre</div>
                        <input
                            value={name} onChange={(e) => setName(e.target.value)} className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"
                        ></input>

                    </div>
                </div>

                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faSignature} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Apellido paterno</div>
                        <input value={lastName1} onChange={(e) => setLastName1(e.target.value)} className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"
                        ></input>
                    </div>
                </div>
                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    <FontAwesomeIcon icon={faSignature} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className="text-lg font-semibold text-gray-700">Apellido materno</div>
                        <input value={lastName2} onChange={(e) => setLastName2(e.target.value)} className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"
                        ></input>
                    </div>
                </div>


                <div className={`rounded-xl p-4 flex items-center shadow-md ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'}`}>
                    <FontAwesomeIcon
                        icon={faIdBadge}
                        className={`text-2xl mr-4 ${hasPaid ? 'text-indigo-600' : 'text-gray-400'}`}
                    />
                    <div>
                        <div className={`text-lg font-semibold ${hasPaid ? 'text-gray-700' : 'text-gray-500'}`}>CURP</div>
                        <input
                            value={curp}
                            onChange={(e) => setCurp(e.target.value)}
                            className={`text-gray-600 ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'} border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border`}
                            disabled={!hasPaid}
                        />
                    </div>
                </div>



                <div className={`rounded-xl p-4 flex items-center shadow-md ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'}`}>
                    <FontAwesomeIcon icon={faCalendarAlt} className={`text-2xl mr-4 ${hasPaid ? 'text-indigo-600' : 'text-gray-400'}`} />
                    <div>
                        <div className={`text-lg font-semibold ${hasPaid ? 'text-gray-700' : 'text-gray-500'}`}>Fecha de nacimiento</div>
                        <input value={birthDate} type="date" onChange={(e) => setBirth_Date(e.target.value)} className={`text-gray-600 ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'} border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border`} disabled={!hasPaid}
                        ></input>
                    </div>
                </div>

                <div className={`rounded-xl p-4 flex items-center shadow-md ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'}`}>
                    <FontAwesomeIcon icon={faUserGroup} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className={`text-lg font-semibold ${hasPaid ? 'text-gray-700' : 'text-gray-500'}`}>¿La propiedad que deseas alquilar es sólo para ti?</div>
                        <select value={hasCompanions} onChange={(e) => setHasCompanions(e.target.value === "true")} className={`text-gray-600 ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'} border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border`} disabled={!hasPaid}
                        >
                            <option value={false}>Sí</option>
                            <option value={true}>No</option>
                        </select>
                    </div>
                </div>

                {hasCompanions &&
                    (<CompanionsForm
                        companions={companions}
                        setCompanions={setCompanions}
                        tenants={tenants}
                        setTenants={setTenants}
                        hasPets={hasPets}
                        setHasPets={setHasPets}
                        minors={minors}
                        setMinors={setMinors}

                    />)}


                <div className={`rounded-xl p-4 flex items-center shadow-md ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'}`}>
                    <FontAwesomeIcon icon={faMapLocationDot} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className={`text-lg font-semibold ${hasPaid ? 'text-gray-700' : 'text-gray-500'}`}>¿Cuál es el motivo del viaje?</div>
                        <select value={tripReason} onChange={(e) => setTripReason(e.target.value)} className={`text-gray-600 ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'} border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border`} disabled={!hasPaid}
                        >
                            <option value=">Trabajo">Trabajo</option>
                            <option value="Estudio">Estudio</option>
                            <option value="Vacaciones">Vacaciones</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>

                <div className={`rounded-xl p-4 flex items-center shadow-md ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'}`}>
                    <FontAwesomeIcon icon={faCircleInfo} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className={`text-lg font-semibold ${hasPaid ? 'text-gray-700' : 'text-gray-500'}`}>Presentate con la comunidad y cuéntales sobre ti</div>
                        <textarea value={presentation} onChange={(e) => setPresentation(e.target.value)} className={`text-gray-600 ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'} border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border`} disabled={!hasPaid}
                        ></textarea>
                    </div>
                </div>

                <div className={`rounded-xl p-4 flex items-center shadow-md ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'}`}>
                    <FontAwesomeIcon icon={faPeopleGroup} className="text-indigo-600 text-2xl mr-4" />
                    <div>
                        <div className={`text-lg font-semibold ${hasPaid ? 'text-gray-700' : 'text-gray-500'}`}>Situacion laboral actual</div>
                        <select
                            value={jobSituation}
                            onChange={(e) => setJobSituacion(e.target.value)}
                            className={`text-gray-600 ${hasPaid ? 'bg-gray-100' : 'bg-gray-200'} border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border`} disabled={!hasPaid}

                        >
                            <option value="Asalariado">Asalariado</option>
                            <option value="Servidor Público">Servidor Público</option>
                            <option value="Emprendedor">Emprendedor</option>
                            <option value="Comerciante">Comerciante</option>
                            <option value="Autoempleado">Autoempleado</option>
                            <option value="Pensionado">Pensionado</option>
                            <option value="Estudiante">Estudiante</option>
                            <option value="Otro">Otro</option>
                        </select>
                    </div>
                </div>


                <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                    {!isLoading ? (<div>
                        <button type="submit" onClick={handleClick} className="text-lg font-semibold text-gray-700 ">
                            Guardar
                        </button>
                    </div>) : (<Spinner />)}
                </div>

                {successMessage && (
                    <div className="bg-green-100 text-green-700 p-4 rounded-md mt-4">
                        {successMessage}
                    </div>
                )}

                {errorMessage && (
                    <div className="bg-red-100 text-red-700 p-4 rounded-md mt-4">
                        {errorMessage}
                    </div>
                )}


            </div>
            <Success show={showSuccessModal} text={text} />
        </div>
    );
}

const CompanionsForm = ({ companions, setCompanions, tenants, setTenants, hasPets, setHasPets, minors, setMinors }) => {
    return (
        <>
            <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                <FontAwesomeIcon icon={faPeopleGroup} className="text-indigo-600 text-2xl mr-4" />
                <div>
                    <div className="text-lg font-semibold text-gray-700">¿Con quién vas a compartir la propiedad?</div>
                    <select
                        value={companions}
                        onChange={(e) => setCompanions(e.target.value)}
                        className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"

                    >
                        <option value="Familia">Familia</option>
                        <option value="Pareja">Pareja</option>
                        <option value="Hijos">Hijos</option>
                        <option value="Roomies">Roomies</option>
                        <option value="Amigos">Amigos</option>
                    </select>
                </div>
            </div>

            <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                <FontAwesomeIcon icon={faHashtag} className="text-indigo-600 text-2xl mr-4" />
                <div>
                    <div className="text-lg font-semibold text-gray-700">Cantidad de huéspedes</div>
                    <input
                        value={tenants}
                        onChange={(e) => setTenants(e.target.value)}
                        className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"

                    />
                </div>
            </div>
            <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                <FontAwesomeIcon icon={faHashtag} className="text-indigo-600 text-2xl mr-4" />
                <div>
                    <div className="text-lg font-semibold text-gray-700">Cantidad de huéspedes menores de 12 años</div>
                    <input
                        value={minors}
                        onChange={(e) => setMinors(e.target.value)}
                        className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"

                    />
                </div>
            </div>
            <div className="bg-gray-100 rounded-xl p-4 flex items-center shadow-md">
                <FontAwesomeIcon icon={faPaw} className="text-indigo-600 text-2xl mr-4" />
                <div>
                    <div className="text-lg font-semibold text-gray-700">¿Tienes mascotas?</div>
                    <select
                        value={hasPets}
                        onChange={(e) => setHasPets(e.target.value === "true")}
                        className="text-gray-600 bg-gray-100 border border-dotted border-gray-300 p-2 sm:p-3 md:p-2 lg:p-3 rounded w-full max-w-xs sm:max-w-sm md:max-w-md lg:max-w-lg box-border"
                    >
                        <option value={true}>Sí</option>
                        <option value={false}>No</option>
                    </select>
                </div>
            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    token: state.auth.token,
    user: state.auth.userData,
});

export default connect(mapStateToProps)(CompletePassport);