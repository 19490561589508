import { Navigate, useLocation } from 'react-router-dom';

const ProtectedRoute = ({ user, redirectTo = "/auth/login", children }) => {
    const location = useLocation();

    console.log("protectedRoute", user);

    if (!user || Object.keys(user).length === 0) {
        return (
            <Navigate
                to={redirectTo}
                replace
                state={{ from: location }}
            />
        );
    }
    return children;
};

export default ProtectedRoute;
