import { faBars, faChevronLeft, faPaperPlane, faPaperclip } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { getTags, messagesConversation, sendFileConversation, sendMessagesConversation, markAllMessagesAsRead, sendToInvestigation, sendToInvestigationScore } from "../../services/chats.service";
import ContractsModal from "./contracts";
import ReportarModal from "./reportar";
import TagModal from "./tags";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import { Navigate } from "react-router-dom";
import ProfileModal from "../../components/modals/modal-perfil";

const Conversation = ({ chat, token, user, handleBackClick, subTabView, }) => {
  const { id, profile_picture } = chat;
  const [file, setFile] = useState(null);
  const [messages, setMessages] = useState([]);
  const [tags, settags] = useState([]);
  const [selectedTag, setSelectedTag] = useState({ tag_name: '', tag_color: '' });
  const [isTagModalOpen, setIsTagModalOpen] = useState(false);
  const [isReportModalOpen, setIsReportModalOpen] = useState(false);
  const [isContracModalOpen, setIsContracModalOpen] = useState(false);
  const [loading, setLoading] = useState(false); // Estado de carga añadido
  const [sending, setSending] = useState(false);
  const navigate = useNavigate();


  const [message, setMessage] = useState("");
  const [menuOpen, setMenuOpen] = useState(false);
  const [propertyId, setPropertyId] = useState(null);
  const [other_user_id, setother_user_id] = useState(null);
  const [currentUser, setcurrentUser] = useState(null);
  const [has_report, sethas_report] = useState(null);
  const messageContainerRef = useRef(null);
  const userMenuRef = useRef(null);
  const footerRef = useRef(null);
  const [showProfileModal, setShowProfileModal] = useState(false);

  const useMediaQuery = (query) => {
    const [matches, setMatches] = useState(window.matchMedia(query).matches);

    useEffect(() => {
      const media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches);
      }
      const listener = () => setMatches(media.matches);
      media.addEventListener('change', listener);
      return () => media.removeEventListener('change', listener);
    }, [matches, query]);

    return matches;
  };

  const isMobile = useMediaQuery('(max-width: 768px)');

  useEffect(() => {
    const CurrentUser = Number(chat.owner_id) === Number(user.userId);
    setcurrentUser(CurrentUser);
  }, [chat.owner_id, user.userId]);

  useEffect(() => {
    const calculateHeight = () => {
      const totalHeight = window.innerHeight * 0.01 * 100; // Calcula 100vh en píxeles
      const userMenuHeight = userMenuRef.current.offsetHeight;
      const footerHeight = footerRef.current.offsetHeight;
      const calculatedHeight = totalHeight - userMenuHeight - footerHeight;
      if (isMobile) {
        messageContainerRef.current.style.height = `${calculatedHeight - 80}px`;
      } else {
        messageContainerRef.current.style.height = `${calculatedHeight}px`;
      }
    };

    calculateHeight();
    window.addEventListener("resize", calculateHeight);
    return () => window.removeEventListener("resize", calculateHeight);
  }, [isMobile]);

  const openProfileModal = () => {
    setShowProfileModal(true);
    console.log("abrir perfil");
  }

  const closeProfileModal = () => {
    setShowProfileModal(false);
  }

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];
    setFile(selectedFile);

    try {
      await sendFileConversation(
        token,
        id,
        selectedFile,
        selectedFile.size,
        selectedFile.type
      );
      getMessageReceive();
    } catch (error) {
      console.error("Error sending file:", error);
    }
  };

  const handleButtonClickDocuments = () => {
    window.open
  }

  const handleNavigate = () => {
    navigate('/admin/personal-panel/my-documents')
  }

  const getMessageReceive = async () => {
    try {
      setLoading(true); // Inicia la carga
      const resp = await messagesConversation(id, token);
      if (resp.chat.has_report == true) {
        sethas_report(resp.chat.has_report);
      } else {
        sethas_report(false);
        markAllMessagesAsRead(id);
      }

      if (resp.messages) {
        if (resp.chat.property_id) {
          setPropertyId(resp.chat.property_id);
          setother_user_id(resp.chat.other_user_id);
        }
        setMessages(resp.messages);
      } else {
        setMessages([]);
      }
    } catch (error) {
      console.error("Error fetching messages:", error);
      setMessages([]);
    } finally {
      setLoading(false); // Finaliza la carga
    }
  };

  const onSendMessage = async () => {
    if (sending || message.trim() === "") return; // Evita múltiples envíos
    setSending(true); // Bloquea el envío de mensajes
    try {
      await sendMessagesConversation(id, { message }, token);
      setMessage("");
      getMessageReceive();
    } catch (e) {
      console.log(e);
    } finally {
      setSending(false); // Desbloquea el envío de mensajes
    }
  };

  useEffect(() => {
    getMessageReceive();
    const intervalId = setInterval(() => {
      getMessageReceive();
    }, 30000);

    return () => clearInterval(intervalId);
  }, [id]);

  const messagesRows = useMemo(() => {
    return messages.slice().reverse();
  }, [messages]);

  // Función para buscar el tag por ID
  const fetchTagById = async (tagId) => {
    try {
      const tagsResponse = await getTags(token); // Suponiendo que esta función trae todos los tags
      const foundTag = tagsResponse.data.tags.find(tag => tag.id === tagId);
      if (foundTag) {
        setSelectedTag({ tag_name: foundTag.name, tag_color: foundTag.color });
      }
    } catch (error) {
      console.error('Error fetching tag:', error);
    }
  };

  // useEffect que se ejecuta cuando el chat cambia
  useEffect(() => {
    if (chat && chat.tag_id) {
      console.log('Tag ID:', chat.tag_id);

      fetchTagById(chat.tag_id); // Busca el tag cada vez que cambias de chat
    }
  }, [chat]);

  const renderMessage = (item, index) => {
    const isCurrentUser = Number(item.user_id) === Number(user.userId);
    if (item.is_system_message) {
      return (

        <div className="flex items-center mt-28">
          <div className="hidden md:flex border-t border-1 border-gray-400 flex-grow"></div>
          <div
            key={index}
            className={`flex items space-x-3 max-w-[100%] md:max-w-[50%] w-full self-center justify-center break-words`}
          >
            <img
              className="h-8 w-8 rounded-full mt-3"
              src={item.profile_picture ? item.profile_picture : "https://via.placeholder.com/150"}
              alt={item.name}
            />
            <div>
              <p className="text-gray-500 text-sm mt-1 text-left text-xs">
                {moment(item.created_at).format("DD MMM, hh:mm A")}
              </p>
              <div className={`text-${isCurrentUser ? "white" : "gray-700"} text-center`}>
                <p>{item.message}</p>
              </div>
              {item.button_message && (
                <div className="text-center">
                  {item.url ? (<a
                    href={item.url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="bg-purple-600 text-white p-1 rounded-full shadow-lg flex items-center justify-center hover:bg-purple-700 transition duration-300"
                  >
                    {item.button_message}
                  </a>)
                    : (<button className="bg-purple-600 text-white p-1 rounded-full shadow-lg flex items-center justify-center hover:bg-purple-700 transition duration-300 w-full" onClick={handleNavigate}>{item.button_message}</button>)
                  }
                </div>
              )}
            </div>
          </div>
          <div className="hidden md:flex border-t border-1 border-gray-400 flex-grow"></div>
        </div>


      )
    }
    if (item.message_type === "text") {
      return (
        <div
          key={index}
          className={`flex items-${isCurrentUser ? "end" : "start"} space-x-3 max-w-[50%] md:max-w-[50%] w-full self-${isCurrentUser ? "end" : "start"} justify-${isCurrentUser ? "end" : "start"} break-words`}
        >
          <div>
            <div className={`bg-${isCurrentUser ? "purple-800" : "white"} p-3 rounded-lg shadow-md text-${isCurrentUser ? "white" : "gray-700"}`}>
              <p>{item.message}</p>
            </div>
            <p className="text-gray-500 text-sm mt-1 text-right">
              {moment(item.created_at).format("DD MMM, hh:mm A")}
            </p>
          </div>
          <img
            className="h-8 w-8 rounded-full"
            src={item.profile_picture ? item.profile_picture : "https://via.placeholder.com/150"}
            alt={item.name}
          />
        </div>
      );
    } else if (item.message_type === "image") {
      return (
        <div
          key={index}
          className={`flex items-${isCurrentUser ? "end" : "start"} space-x-3 max-w-[50%] md:max-w-[50%] w-full self-${isCurrentUser ? "end" : "start"} justify-${isCurrentUser ? "end" : "start"} break-words`}
        >
          <div>
            <img
              className="max-w-full"
              src={item.message_file}
              alt="Imagen"
            />
            <p className="text-gray-500 text-sm mt-1 text-right">
              {moment(item.created_at).format("DD MMM, hh:mm A")}
            </p>
          </div>
          <img
            className="h-8 w-8 rounded-full"
            src={item.profile_picture ? item.profile_picture : "https://via.placeholder.com/150"}
            alt={item.name}
          />
        </div>
      );
    } else if (item.message_type === "file") {
      return (
        <div
          key={index}
          className={`flex items-${isCurrentUser ? "end" : "start"} space-x-3 max-w-[50%] md:max-w-[50%] w-full self-${isCurrentUser ? "end" : "start"} break-words`}
        >
          <div>
            <a
              href={item.message_file}
              target="_blank"
              rel="noopener noreferrer"
              className={`bg-${isCurrentUser ? "purple-800" : "white"} p-3 rounded-lg shadow-md text-${isCurrentUser ? "white" : "gray-700"}`}
            >
              Descargar archivo
            </a>
            <p className="text-gray-500 text-sm mt-1 text-right">
              {moment(item.created_at).format("DD MMM, hh:mm A")}
            </p>
          </div>
          <img
            className="h-8 w-8 rounded-full"
            src={item.profile_picture ? item.profile_picture : "https://via.placeholder.com/150"}
            alt={item.name}
          />
        </div>
      );
    }
  };

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const handleReportar = async () => {
    setIsReportModalOpen(true);
  };


  const handleTags = () => {
    setIsTagModalOpen(true);
  };

  const handleSolicitarInvestigacion = async () => {
    try {
      const response = await axios.post(`/app/chats/properties/${propertyId}/investigate/${chat.user_id}`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });
      if (response.status === 201) {
        getMessageReceive();
      }
    }
    catch (error) {
      console.error("Error solicitando investigacion:", error);
    }

  }

  const handleVerificarIdentidad = async () => {
    try {
      const response = await axios.post(`/app/chats/properties/${propertyId}/investigate-identity/${chat.user_id}`, {}, {
        headers: { Authorization: `Bearer ${token}` },
      });

      if (response.status === 201) {
        getMessageReceive();
      }

    } catch (error) {
      console.error("Error solicitando verificación de identidad:", error);
    }
  }

  const refreshChat = async () => {
    getMessageReceive();
  };

  const closeTagModal = (nuevoTag) => {
    console.log(nuevoTag);

    setIsTagModalOpen(false);
    if (nuevoTag) {
      // Actualiza el estado con el nuevo tag
      setSelectedTag({ tag_name: nuevoTag.name, tag_color: nuevoTag.color });
    }
  };

  const openPropertySettings = () => {
    navigate(`/propiedad/${chat.property_id}/detalle`);
  }

  return (
    <div className="flex flex-col h-full">
      <div id="user-menu" ref={userMenuRef} className="flex items-center justify-between p-4">
        <div className="flex items-center space-x-4">
          <button onClick={handleBackClick} className="text-gray-600 mr-2">
            <FontAwesomeIcon icon={faChevronLeft} />
          </button>
          <span className="relative flex h-12 w-12 overflow-hidden rounded-full border-2 border-purple-800 cursor-pointer" onClick={openProfileModal}>
            <img
              className="h-full w-full object-cover"
              src={profile_picture ? profile_picture : "https://via.placeholder.com/150"}
              alt="Avatar"
            />
          </span>
          <div>
            <p className="font-bold text-lg text-gray-800">{chat.user}</p>
            <p className="text-purple-800 cursor-pointer" onClick={openPropertySettings}>{chat.property}</p>

          </div>

          <div>
            <p className={`font-bold rounded-full ml-2 text-md px-4 py-1`} style={{ backgroundColor: '#' + selectedTag.tag_color }}>
              {selectedTag.tag_name}
            </p>
          </div>

        </div>
        {!has_report && (
          <div className="relative">

            <button onClick={toggleMenu} className="focus:outline-none">
              <FontAwesomeIcon icon={faBars} className="w-6 h-6 text-gray-800" />
            </button>
            {menuOpen && (
              <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded-lg shadow-lg">
                <button onClick={handleReportar} className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left">Reportar chat</button>
                {currentUser && (
                  <>
                    <button onClick={handleTags} className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left">Agregar Tag</button>
                    <button onClick={handleVerificarIdentidad} className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left">Solicitar Cita Segura</button>
                    <button onClick={handleSolicitarInvestigacion} className="block px-4 py-2 text-gray-800 hover:bg-gray-200 w-full text-left">Solicitar investigación</button>
                  </>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      <div ref={messageContainerRef} className="flex-grow bg-gray-100 rounded-lg p-4 overflow-auto">
        <div className="flex flex-col space-y-4">
          {loading ? (
            <p className="text-gray-600">Cargando mensajes...</p>
          ) : messagesRows.length > 0 ? (
            messagesRows.map((item, index) => renderMessage(item, index))
          ) : (
            <p className="text-gray-600">No hay mensajes disponibles</p>
          )}
        </div>
      </div>
      {
        has_report ? (
          <div ref={footerRef} id="footer" className="text-white p-4 rounded-md my-2">
            <div className="bg-red-500 p-4 rounded-md shadow-lg">
              <p className="font-bold text-lg">¡Alerta!</p>
              <p>Este chat ha sido reportado</p>
            </div>
          </div>
        ) : (
          <div ref={footerRef} id="footer" className="p-4 flex items-center space-x-2 bg-white rounded-full w-full">
            <input
              type="text"
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey && message.trim() !== "" && !sending) {
                  e.preventDefault(); // Evita el comportamiento predeterminado
                  onSendMessage();
                }
              }}
              className="flex rounded-full border border-gray-300 p-3 focus:outline-none focus:ring-2 focus:ring-purple-800 focus:border-transparent transition-colors duration-200 w-full"
              placeholder="Escribe tu mensaje..."
            />
            <label
              htmlFor="file-upload"
              className="bg-gray-200 hover:bg-gray-300 text-gray-800 font-bold py-3 px-4 rounded-full cursor-pointer flex items-center space-x-2 transition-colors duration-200"
            >
              <FontAwesomeIcon icon={faPaperclip} className="w-5 h-5" />
              <input
                type="file"
                onChange={handleFileChange}
                className="hidden"
                id="file-upload"
              />
            </label>
            <button
              onClick={!sending && message.trim() !== "" ? onSendMessage : null}
              className={`bg-purple-800 text-white rounded-full p-3 hover:bg-purple-600 transition ease-in-out duration-300 transform hover:scale-105 flex items-center justify-center ${message === "" ? "cursor-not-allowed opacity-50" : ""}`}
              disabled={message === ""}
            >
              <FontAwesomeIcon icon={faPaperPlane} className="w-5 h-5" />
            </button>
          </div>
        )
      }

      <TagModal
        isOpen={isTagModalOpen}
        onClose={closeTagModal}
        token={token}
        chatId={chat.id}
      />
      <ReportarModal
        isOpen={isReportModalOpen}
        onClose={() => setIsReportModalOpen(false)}
        token={token}
        chatRoomId={id}
        onReported={refreshChat} // Pasar la función de refresh como un callback
      />
      <ContractsModal
        isOpen={isContracModalOpen}
        onClose={() => setIsContracModalOpen(false)}
        token={token}
        userId={user.userId}
        propertyId={propertyId}
      />
      <ProfileModal
        show={showProfileModal}
        close={closeProfileModal}
        userId={subTabView === 2 ? chat.user_id : subTabView === 3 ? chat.owner_id : null}
        subTabView={subTabView}
      />
    </div >
  );
};

export default Conversation;
